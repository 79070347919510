.description-section {
  padding: 90px 0 100px 0;
  .text-content {
    h2 {
      color: white;
      text-transform: uppercase;
      user-select: none;
      span {
        background: linear-gradient(90deg, $primary 40%, $primary_2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $titleFont !important;
        display: inline-block;
        margin-left: 10px;
      }
    }
    .desc {
      margin: 25px 0;
    }
    p {
      color: white;
      text-align: justify;
      font-size: 18px;
      line-height: normal;
    }
    ul {
      @include displayFlex(flex, center, flex-start);
      flex-wrap: wrap;
      margin-bottom: 20px;
      li {
        font-size: 16px;
        color: white;
        margin: 5px 10px 5px 0;
        padding: 6px 12px;
        border-radius: 30px;
        background-color: rgba($color: $textColor_1, $alpha: 0.1);
        .fi {
          margin-right: 10px;
          font-size: 18px;
          position: relative;
          top: 3px;
        }
      }
    }
    .button-group {
      margin-top: 2rem;
      .btn {
        min-width: 180px;
      }
    }
  }
  .image-content {
    position: relative;
    .img-inner {
      width: 320px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    &.width-100 {
      .img-inner {
        width: 100%;
      }
    }
    &.width-90 {
      .img-inner {
        width: 90%;
      }
    }
    &.border-radius {
      .img-inner {
        img {
          border-radius: 42px;
        }
      }
    }
    &:hover {
      .shapes-grp .shape {
        &.shape-1 {
          height: 180px;
        }
        &.shape-2 {
          height: 280px;
        }
        &.shape-3 {
          height: 220px;
        }
      }
    }
  }
  .shapes-grp {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    width: 400px;
    height: 100%;
    z-index: -1;
    .shape {
      display: block;
      position: absolute;
      // background: linear-gradient(180deg, $secondary, $secondary_2);
      background: linear-gradient(180deg, #a7c87b, #4b6533);
      transform: skewX(-20deg);
      box-shadow: 8px 8px 10px -1px rgba($color: black, $alpha: 0.3);

      &::before {
        content: "";
        position: absolute;
        right: 10px;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: rgba($color: white, $alpha: 0.7);
      }
      &.shape-1 {
        left: 0;
        bottom: 0;
        width: 100px;
        height: 160px;
        z-index: 3;
        transition: height 0.3s ease-in-out;
      }
      &.shape-2 {
        left: 80px;
        bottom: 0;
        width: 180px;
        height: 250px;
        z-index: 2;
        transition: height 0.5s ease-in-out;
      }
      &.shape-3 {
        left: 220px;
        bottom: 0;
        width: 100px;
        height: 210px;
        z-index: 1;
        transition: height 0.4s ease-in-out;
      }
    }
    .circle {
      position: absolute;
      left: 0;
      top: 120px;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      border: 2px dashed rgba($color: white, $alpha: 0.7);
      animation: rotatePlant 20s linear infinite;
    }
  }
}
@media (max-width: 992px) {
  .description-section {
    padding: 76px 0 100px 0;
    background-size: 400px;
    background-position: left 80%;
    .text-content {
      h2 {
        text-align: center;
        font-size: 36px;
      }
      p {
        text-align: center;
      }
      ul {
        justify-content: center;
        flex-wrap: wrap;
        padding-left: 0;
      }
      .button-group {
        text-align: center;
      }
    }
    .image-content {
      margin-top: 2rem;
    }
  }
}
@media (max-width: 768px) {
  .description-section {
    padding: 50px 0 80px 0;
    background-size: 300px;
    .text-content {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .image-content {
      overflow: hidden;
      .img-inner {
        width: 300px;
      }
    }
    .shapes-grp {
      transform: translateX(-50%) scale(0.8);
      bottom: 0;
      width: 100%;
      .shape {
        &.shape-1 {
          left: 10%;
        }
        &.shape-2 {
          left: 30%;
        }
        &.shape-3 {
          left: 60%;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .description-section {
    padding: 50px 0 60px 0;
    .text-content {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .image-content {
      &.pepe-character {
        overflow: hidden;
        margin-top: 3rem;
        .img-inner {
          width: 280px;
        }
      }
      .img-inner {
        width: 70%;
      }
    }
  }
}
