.modal-backdrop {
  z-index: -1;
}
.modal {
  display: none;
  &.show {
    display: block;
    .modal-content {
      animation: scaleAnim 0.4s cubic-bezier(0.5, 1, 0.1, 1.275);
    }
  }
}
.modal-content {
  border: none;
  border-radius: 20px;
}
.modal-title {
  i {
    font-size: 20px;
    margin-right: 10px;
  }
}
.modal-body {
  p {
    font-size: 16px;
    font-weight: 500;
    word-wrap: anywhere;
    white-space: break-spaces;
  }
  iframe {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.modal-footer {
  .btn {
    min-width: 120px;
    margin: 5px;
    padding: 0.65rem 1.2rem 0.65rem 3rem;
    .fi {
      position: absolute;
      left: 18px;
      top: 13px;
      margin-right: 10px;
      color: white;
    }
  }
}

.modal-alert {
  .btn-close {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    opacity: 1;
    font-size: 12px;
    background-color: silver;
    z-index: 50;
    &:hover {
      background: $error
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
  .modal-status {
    padding: 3rem 2rem 0 2rem;
    text-align: center;
    .icon {
      width: 48px;
      height: 48px;
      margin: 0 auto 1.5rem auto;
      border-radius: 50%;
      color: white;
      font-size: 24px;
      position: relative;
      @include displayFlex(flex, center, center);
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 130%;
        height: 130%;
        border-radius: 50%;

        z-index: -1;
      }
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 165%;
        height: 165%;
        border-radius: 50%;

        z-index: -1;
      }
    }
    h4 {
      text-transform: uppercase;
    }
    &.success {
      .icon {
        background-color: $success;
        &::before {
          background-color: rgba($color: $success, $alpha: 0.3);
        }
        &::after {
          background-color: rgba($color: $success, $alpha: 0.1);
        }
      }
      h4 {
        color: $success;
      }
    }
    &.error {
      .icon {
        background-color: $error;
        &::before {
          background-color: rgba($color: $error, $alpha: 0.3);
        }
        &::after {
          background-color: rgba($color: $error, $alpha: 0.1);
        }
      }
      h4 {
        color: $error;
      }
    }
    &.warning {
      .icon {
        background-color: $warning;
        &::before {
          background-color: rgba($color: $warning, $alpha: 0.3);
        }
        &::after {
          background-color: rgba($color: $warning, $alpha: 0.1);
        }
      }
      h4 {
        color: $warning;
      }
    }
  }
  .modal-body {
    text-align: center;
  }
  .modal-footer {
    justify-content: center;
    border-top: none;
    .btn {
      width: 100%;
      padding: 0.65rem 1.2rem 0.65rem 1.2rem;
    }
  }
}

.modal-loading {
  .btn-close {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    opacity: 1;
    font-size: 12px;
    background-color: silver;
    z-index: 50;
    &:hover {
      background: $error
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
  .icon {
    width: 170px;
    height: 125px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h6 {
    margin-bottom: 0;
    text-align: center;
    font-family: $bodyFont;
    font-weight: 500;
  }
  .modal-dialog {
    max-width: 300px;
  }
  .modal-body {
    padding: 2rem;
  }
}

@media (max-width: 575px) {
  .modal-loading {
    .modal-dialog {
      margin: 0 auto;
      padding: 0 1rem;
    }
  }
}
