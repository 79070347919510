@keyframes rotatePlant {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes MoveUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes heightAnime {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes jumpAnimation {
  33% {
    text-shadow: 0 30px $primary, 0 20px $accent;
  }
  50% {
    transform: translate(0, 0) rotate(-4deg);
    text-shadow: 0 0px #8fc0a9, 0 0px #84a9ac;
  }
  70% {
    text-shadow: 0 -30px $secondary, 0 -20px $secondary_2;
  }
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes blickanim {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes swipeLeftsmall {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes swipeRightsmall {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes swipeUpsmall {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleAnim {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes swipeinfiniti_left {
  0%,
  100% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(0);
  }
}
@keyframes swipeinfiniti_up {
  0%,
  100% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
}
