.hero-home-section {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
  }
  height: 100vh;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(100% 0, 100% 92%, 70% 100%, 0 92%, 0 0);
    position: relative;
    z-index: 10;
  }
  .img-content {
    background-image: url(../assets/images/pitbull-2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    clip-path: polygon(100% 0, 100% 92%, 70% 100%, 0 92%, 0 0);
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0.1) 70%
    );
    clip-path: polygon(100% 0, 100% 92%, 70% 100%, 0 92%, 0 0);
    z-index: 11;
  }
  .text-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include displayFlex(flex, center, center);
    flex-direction: column;
    z-index: 12;
    .text-inner {
      width: 600px;
      margin: 0 auto;
    }
    h1 {
      font-size: 45px;
      font-weight: 800;
      text-align: center;
      color: white;
      line-height: normal;
      text-transform: uppercase;

      text-shadow: 0 5px 30px rgba($color: #000000, $alpha: 0.3);
      // span {
      //   background: linear-gradient(90deg, $secondary, $secondary_2);
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   font-family: $titleFont;
      //   font-weight: 800;
      // }
    }
    p {
      margin-top: 1.5rem;
      color: white;
      font-size: 18px;
      text-align: center;
    }
    .btns-group {
      text-align: center;
      margin-top: 20px;
    }
  }
}

.swiper-hero-index {
  clip-path: polygon(0% 0%, 100% 0, 100% 85%, 30% 100%, 0 85%);
  .swiper-slide {
    position: relative;
    height: 800px;
    .back-image {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 100%;
      width: 100%;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(29, 25, 39, 0.3),
          rgba(107, 83, 246, 0.3)
        );
        z-index: 10;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      padding-top: 170px;
    }
    .text-content {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 15px;
      padding: 20px;
      h1 {
        color: white;
        text-transform: uppercase;
        span {
          background: linear-gradient(90deg, $secondary, $secondary_2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: $titleFont;
          font-weight: 800;
        }
      }
      .desc {
        border-left: 2px solid white;
        margin: 25px 0;
        p {
          padding-left: 15px;
          color: white;
        }
      }
    }
    .image-content {
      .img-inner {
        width: 400px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba($color: white, $alpha: 0.1);
    &::after {
      font-family: $iconFont_Regular;
      font-size: 24px;
      color: white;
    }
  }
  .swiper-button-next {
    &::after {
      content: "\f14b";
    }
  }
  .swiper-button-prev {
    &::after {
      content: "\f14a";
    }
  }
}

.page-hero {
  background-image: url(../assets/images/bg/page-header-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  padding: 110px 0;
  margin-top: 107px;
  border-radius: 0 0 0 120px;
  .header-inner {
    width: 700px;
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 30px;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}

.hero-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(30, 28, 32, 0.4), #1d1927);
  }
  .inner {
    padding: 180px 0 120px 0;
    position: relative;
    z-index: 50;
  }
  .text-content {
    text-align: center;
    h2 {
      color: white;
    }
    span {
      background: linear-gradient(90deg, $primary, $primary_2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 1199px) {
  .hero-home-section {
    .text-content {
      h1 {
        font-size: 40px;
      }
    }
  }
  .swiper-hero-index {
    .swiper-slide {
      height: unset;
      min-height: 840px;
      .image-content {
        .img-inner {
          width: 350px;
        }
      }
      .back-image {
        transform: scale(1.05);
      }
      .text-content {
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .hero-home-section {
    .text-content {
      .text-inner {
        width: 70%;
      }
      h1 {
        font-size: 38px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .swiper-hero-index {
    clip-path: polygon(0% 0%, 100% 0, 100% 89%, 30% 100%, 0 89%);
    .swiper-slide {
      .info-content {
        padding-top: 140px;
        padding-bottom: 220px;
      }
      .text-content {
        margin-bottom: 20px;
        h1 {
          font-size: 34px;
        }
        .desc {
          p {
            font-size: 14px;
          }
        }
      }
      .image-content {
        .img-inner {
          width: 250px;
        }
      }
    }
  }
  .page-hero {
    margin-top: 68px;
    padding: 100px 0;
    border-radius: 0 0 0 100px;
    .header-inner {
      h1 {
        font-size: 28px;
      }
    }
  }
  .hero-bg-image {
    .inner {
      padding: 150px 0 80px 0;
    }
    .text-content h2 {
      font-size: 34px;
    }
  }
}

@media (max-width: 768px) {
  .hero-home-section {
    .text-content {
      .text-inner {
        width: 100%;
        padding: 0 20px;
      }
      h1 {
        font-size: 32px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .swiper-hero-index {
    .swiper-slide {
      .info-content {
        padding-top: 140px;
        padding-bottom: 220px;
      }
      .text-content {
        margin-bottom: 20px;
        h1 {
          font-size: 30px;
        }
        .desc {
          p {
            font-size: 13px;
          }
        }
      }
      .image-content {
        .img-inner {
          width: 250px;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      &::after {
        font-size: 20px;
      }
    }
  }
  .page-hero {
    padding: 90px 0;
    border-radius: 0 0 0 90px;
    .header-inner {
      width: 100%;
      h1 {
        font-size: 26px;
      }
    }
  }
  .hero-bg-image {
    .inner {
      padding: 140px 0 60px 0;
    }
    .text-content h2 {
      font-size: 30px;
    }
  }
}
@media (max-width: 630px) {
  .swiper-hero-index {
    .swiper-button-next,
    .swiper-button-prev {
      top: unset;
      bottom: 250px;
    }
  }
}
@media (max-width: 575px) {
  .swiper-hero-index {
    .swiper-slide {
      .info-content {
        padding-top: 120px;
        padding-bottom: 210px;
      }
      .image-content {
        .img-inner {
          width: 200px;
        }
      }
    }
  }
  .page-hero {
    margin-top: 60px;
    padding: 80px;
    border-radius: 0 0 0 80px;
    .header-inner {
      h1 {
        font-size: 24px;
      }
    }
  }
  .hero-bg-image {
    .inner {
      padding: 120px 0 50px 0;
    }
    .text-content h2 {
      font-size: 28px;
    }
  }
}
