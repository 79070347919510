.table-section {
  margin-top: 1.2rem;
  max-height: 350px;
  overflow-y: auto;
  background-color: $background_dark2;
  padding: 10px;
  border-radius: 15px;
  .table {
    margin-bottom: 0;
  }
}
.table > :not(caption) > * > * {
  padding: 0.7rem;
  background-color: $background_dark2;
  border-color: rgba($color: white, $alpha: 0.15);
}

@media (max-width: 575px) {
  .shopping-table-section {
    .table {
      thead {
        display: none;
      }
      > :not(caption) > * > * {
        display: block;
        border: none;
        padding: 0.2rem 0.6rem;
      }
      tr {
        border-bottom: 1px solid rgba($color: white, $alpha: 0.15);
        &:last-child {
          border: none;
        }
      }
      th,
      td {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
