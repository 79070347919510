.footer {
  margin-top: 4rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #1d1927, $background_dark2);
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(../assets/images/bg/footer-bg-2.png);
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  //   opacity: 0.5;
  //   z-index: 1;
  // }
  .footer-inner {
    position: relative;
    padding: 100px 0 10px 0;
    z-index: 10;
  }

  .info {
    text-align: left;
    .logo {
      width: 150px;
      a {
        display: block;
      }
      img {
        width: 100%;
      }
    }
    p {
      color: rgba($color: white, $alpha: 0.8);
      text-align: left;
      margin: 10px 0 0 0;
    }
    .incubated {
      margin-top: 20px;
      @include displayFlex(flex, center, flex-start);
      h6 {
        margin: 0 10px 0 0;
        font-weight: 500;
        color: white;
      }
      img {
        width: 100px;
      }
    }
  }
  .menu-links {
    ul {
      text-align: center;
      li {
        margin: 10px;
        a {
          color: white;
          display: block;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
  .social-icons {
    ul {
      border-radius: 20px;
      padding: 5px 20px;
      @include displayFlex(flex, center, center);
      li {
        a {
          width: 64px;
          height: 64px;
          background-color: rgba($color: white, $alpha: 0.15);
          margin: 5px;
          border-radius: 2px;
          @include displayFlex(flex, center, center);
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: $secondary;
            i {
              color: white;
            }
          }
          i {
            color: white;
            font-size: 24px;
          }
        }
        &.discord {
          &:hover {
            a {
              background-color: #5460e6;
            }
          }
        }
        &.youtube {
          &:hover {
            a {
              background-color: #f20000;
            }
          }
        }
        &.twitter {
          &:hover {
            a {
              background-color: #1c99e6;
            }
          }
        }
        &.telegram {
          &:hover {
            a {
              background-color: #0081c2;
            }
          }
        }
      }
    }
  }
  .footer-bg {
    width: 100%;
    height: 250px;
    background-image: url(../assets/images/bg/bg-footer-3.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
  .copyright {
    background-color: #1c0058;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: white;
    position: relative;
    z-index: 10;
    padding: 20px 0;
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
    span {
      display: inline-block;
      margin-left: 10px;
      color: $secondary_2;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    .footer-inner {
      padding-top: 80px;
    }
    .info {
      text-align: center;
      .logo {
        width: 130px;
        margin: 0 auto;
      }
      p {
        text-align: center;
        font-size: 13px;
      }
      .incubated {
        justify-content: center;
        h6 {
          font-size: 14px;
        }
        img {
          width: 90px;
        }
      }
    }
    .menu-links {
      margin-top: 1.2rem;
      padding-top: 1.2rem;
      border-top: 1px solid rgba($color: white, $alpha: 0.1);
      ul {
        @include displayFlex(flex, center, center);
        flex-wrap: wrap;
        li a {
          font-size: 14px;
        }
      }
    }
    .social-icons {
      ul {
        li a {
          width: 58px;
          height: 58px;
        }
      }
    }
    .footer-bg {
      height: 140px;
    }
  }
}
@media (max-width: 557px) {
  .footer {
    .footer-inner {
      padding-top: 70px;
    }
    .info {
      .logo {
        width: 110px;
      }
      p {
        font-size: 12px;
      }
      .incubated {
        h6 {
          font-size: 13px;
        }
        img {
          width: 80px;
        }
      }
    }
    .social-icons {
      ul {
        li a {
          width: 48px;
          height: 48px;
        }
      }
    }
    .footer-bg {
      height: 100px;
    }
  }
}
