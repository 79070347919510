$primary: #6b53f6;
$primary_2: #0077ff;
$secondary: #ff4a90;
$secondary_2: #ff8862;
$accent: #ffc450;

$purple_1: #4f198e;
$purple_2: #300d6b;

$error: #ea0b0b;
$success: #15d34e;
$warning: #ffcc67;

$textColor_1: #fbf8ff;
$textColor_2: #aea8ba;
$textColor_3: #857e8b;
$textColor_4: #494454;

$background: #1d1927;
$background_dark2: #020202;
$background_darkBlue: #180c44;
$backgroundLight: #f8fbff;

$border: rgba(215, 215, 216, 0.1);
$boxshadow: 0 5px 25px -1px rgba(10, 10, 16, 0.1);

$bodyFont: "Noto Sans";
$titleFont: "Barlow";

$iconFont_Regular: "uicons-regular-rounded";
$iconFont_Solid: "uicons-solid-rounded";

$color_card_epic: #b8f900;
$color_card_legendary: #d97ce4;
$color_card_common: #00c3f7;
$card_background_1: #382570;
$card_background_2: #291a54;
