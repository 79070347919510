.wrapper-buycoin {
  background-image: url(../assets/images/buycoin/buycoin-bg.jpg);
  background-position: center center;
  background-size: cover;
  position: relative;
}

.buycoin-hero-section {
  background-size: cover;
  background-position: center top;
  padding: 170px 0 100px 0;
  position: relative;
  .section-inner {
    background-color: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(10px);
    border-radius: 25px;
    padding: 50px;
    box-shadow: 0 5px 30px rgba(0, 17, 136, 0.2);
  }
  .buycoin-box {
    display: none;
    &.active {
      display: block;
    }
  }
  .text-content {
    padding-right: 20px;
    animation: swipeRightsmall 0.4s ease-in-out;
    h3 {
      margin-bottom: 1.2rem;
      color: white;
    }
    p {
      text-align: justify;
      margin-bottom: 0;
      color: white;
    }
  }
  .image-content {
    animation: swipeRightsmall 0.4s ease-in-out;
    .img-inner {
      width: 350px;
      margin: 0 auto;
    }
    img {
      width: 100%;
    }
  }
  .form-content {
    background-color: $backgroundLight;
    padding: 60px 40px 40px 40px;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 10px 50px -1px rgba($color: #000000, $alpha: 0.1);
    animation: swipeLeftsmall 0.4s ease-in-out;
    .btn-back {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }
  .buttons-group {
    margin-bottom: 25px;
    .btn {
      min-width: 250px;
      margin: 5px auto;
      display: block;
    }
  }
  .buycoin-inputs {
    position: relative;
    padding-right: 50px;
    .btn-circle {
      background-color: white;
      position: absolute;
      right: 0;
      bottom: 50px;
      box-shadow: $boxshadow;
    }
  }
  .inputgroup {
    .form-control {
      box-shadow: $boxshadow;
      transition: all 0.3s ease-in-out;
      border: none;
      color: $background;
      border-radius: 60px;
      padding-left: 25px;
      &:focus {
        ~ label {
          color: $secondary;
          opacity: 1;
        }
      }
    }
    .form-floating {
      > label {
        left: 10px;
        color: $background;
        font-size: 14px;
        padding: 1.1rem 0.75rem;
      }
    }
  }
}

.coin-remaining {
  text-align: center;
  border: 1px solid #f1f2f3;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 15px 10px;
  background-color: #053285;
  h4 {
    color: $secondary_2;
    word-wrap: anywhere;
    white-space: break-spaces;
  }
  h6 {
    color: $secondary_2;
    margin-bottom: 15px;
    word-wrap: anywhere;
    white-space: break-spaces;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    word-wrap: anywhere;
    white-space: break-spaces;
  }
}

.card-wallet-item {
  text-align: center;
  margin-top: 20px;
  .card-inner {
    width: 160px;
    margin: 0 auto;
    &:hover {
      .thumb {
        padding: 15px;
      }
      .wallet-name {
        color: $primary;
      }
    }
  }
  cursor: pointer;
  .thumb {
    width: 100px;
    height: 100px;
    background-color: white;
    box-shadow: $boxshadow;
    padding: 10px;
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.2s ease-in-out;
    img {
      width: 100%;
    }
  }
  .wallet-name {
    margin: 10px 0 0 0;
    font-family: $bodyFont;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
  }
}

.progressing {
  margin: 50px 0;
  .thumb {
    width: 150px;
    margin: 0 auto;
    text-align: center;
    img {
      width: 100%;
    }
    svg {
      width: 80px;
      height: 80px;
      .svg-elem-1 {
        stroke-dashoffset: 115.09733552923255px;
        stroke-dasharray: 115.09733552923255px;
        transition: stroke-dashoffset 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
            0s,
          fill 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
      }
      .svg-elem-2 {
        stroke-dashoffset: 96.24777960769379px;
        stroke-dasharray: 96.24777960769379px;
        transition: stroke-dashoffset 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
            0.12s,
          fill 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
      }
      .svg-elem-3 {
        stroke-dashoffset: 25.3505859375px;
        stroke-dasharray: 25.3505859375px;
        transition: stroke-dashoffset 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
            0.24s,
          fill 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6000000000000001s;
      }
    }
    &.active svg {
      .svg-elem-1 {
        stroke-dashoffset: 0;
      }
      .svg-elem-2 {
        stroke-dashoffset: 0;
        fill: #15d34e;
      }
      .svg-elem-3 {
        stroke-dashoffset: 0;
        stroke: #fff;
      }
    }
  }
  .info {
    margin-top: 15px;
    text-align: center;
    h5 {
      margin-bottom: 15px;
      text-transform: capitalize;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.how-buyCoin {
  margin: 60px 0 100px 0;
  position: relative;
  overflow: hidden;
  .section-inner {
    background-color: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(10px);
    border-radius: 25px;
    padding: 50px;
    box-shadow: 0 5px 30px rgba(0, 17, 136, 0.2);
    &.align-center {
      .text-content {
        width: 700px;
        margin: 0 auto;
      }
    }
    .step-inner {
      margin-bottom: 5rem;
      padding-top: 3rem;
      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
  .text-content {
    position: relative;
    text-align: left;
    h2 {
      color: white;
      margin-bottom: 10px;
    }
    .desc {
      p {
        color: white;
      }
    }
  }
  .image-content {
    &.align-right {
      padding: 0 0 0 30px;
      .img-inner {
        margin-left: auto;
      }
    }
    .img-inner {
      backdrop-filter: blur(10px);
      background-color: rgba($color: white, $alpha: 0.06);
      border: 1px solid rgba($color: white, $alpha: 0.2);
      border-radius: 25px;
      padding: 50px;
    }
    img {
      width: 100%;
      border-radius: 25px;
      object-fit: contain;
    }
  }
}
.spaces-images-group {
  position: relative;
  right: 150px;
  width: 500px;
  height: 500px;
  z-index: 1;
  .group-inner {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: contain;
      &.space_1 {
        animation: rotatePlant 12s linear infinite;
      }
      &.space_2 {
        animation: rotatePlant 18s linear infinite;
      }
      &.space_3 {
        animation: rotatePlant 10s linear infinite;
      }
      &.space_4 {
        animation: rotatePlant 25s linear infinite;
      }
      &.space_5 {
      }
    }
  }
}

@media (max-width: 1400px) {
  .buycoin-hero-section {
    .section-inner {
      width: 100%;
    }
  }
}
@media (max-width: 1199px) {
  .buycoin-hero-section {
    padding-bottom: 80px;
    .section-inner {
      padding: 30px;
    }
  }
}
@media (max-width: 992px) {
  .buycoin-hero-section {
    padding-top: 140px;
    padding-bottom: 60px;
    .section-inner {
      padding: 0 0 30px 0;
      box-shadow: none;
    }
    .form-content {
      box-shadow: none;
      border-radius: 20px 20px 0 0;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
    .text-content {
      padding: 40px 40px 0 40px;
      text-align: center;
      h3 {
        font-size: 30px;
      }
      p {
        text-align: center;
      }
    }
  }
  .progress-group {
    padding: 0 30px;
  }
  .how-buyCoin {
    .section-inner {
      padding: 40px;
      &.align-center {
        .text-content {
          width: 100%;
        }
      }
      .step-inner {
        padding-top: 2.5rem;
        margin-bottom: 4rem;
      }
    }
    .text-content {
      margin-bottom: 1.5rem;
    }
    .image-content {
      padding: 0;
      &.align-right {
        padding: 0;
      }
      &.align-left {
        padding: 0;
      }
      .img-inner {
        width: 400px;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .buycoin-hero-section {
    .section-inner {
      padding-bottom: 50px;
    }
    .form-content {
      padding: 60px 30px 30px 30px;
    }
    .image-content .img-inner {
      width: 300px;
    }
    .text-content {
      padding: 30px 30px 0 30px;
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .how-buyCoin {
    .section-inner {
      padding: 30px;
      .step-inner {
        padding-top: 2rem;
        margin-bottom: 3.5rem;
      }
    }
    .text-content {
      h2 {
        font-size: 32px;
      }
    }
    .image-content {
      .img-inner {
        width: 100%;
      }
    }
  }
}
@media (max-width: 575px) {
  .buycoin-hero-section {
    padding-top: 110px;
    .image-content .img-inner {
      width: 70%;
    }
    .form-content {
      padding: 60px 20px 20px 20px;
    }
    .text-content {
      padding: 20px 20px 0 20px;
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .how-buyCoin {
    .section-inner {
      padding: 20px;
      .step-inner {
        padding-top: 1.5rem;
        margin-bottom: 3rem;
      }
    }
    .text-content {
      h2 {
        font-size: 29px;
      }
    }
  }
}

@media (max-width: 390px) {
  .buycoin-hero-section .buttons-group .btn {
    min-width: unset;
    width: 100%;
  }
}

.btn-circle {
  padding: 5px;
  border-radius: 50%;
  white-space: nowrap;
  position: relative;
  height: 44px;
  width: 44px;
  line-height: 25px;
  border: none;
  i {
    font-size: 22px;
    position: relative;
    top: 3px;
  }
}

.btn-back {
  padding: 8px 15px;
  border: none;
  background-color: rgba($color: $background, $alpha: 0.2);
  border-radius: 50px;
  font-size: 14px;
  display: inline-block;
  position: relative;
  color: $background;
  transition: all 0.2s ease-in-out;
  i {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  &:hover,
  &:focus {
    background-color: $secondary;
    color: white;
  }
}
