.marketplace-section {
  padding-bottom: 5rem;
  .section-inner {
    margin-top: 3rem;
  }
  .btns-group {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
  .page-title {
    .title-inner {
      h2 {
        span {
          background: linear-gradient(90deg, $primary 40%, $primary_2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: $titleFont !important;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }
}
.swiper-marketplace {
  .swiper-wrapper {
    padding-bottom: 3rem;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: white;
    opacity: 0.12;
    &.swiper-pagination-bullet-active {
      background-color: $primary;
      opacity: 1;
      width: 12px;
      height: 12px;
    }
  }
}
