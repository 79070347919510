.faqs-section {
  margin-bottom: 120px;
  background: linear-gradient(180deg, #0d0c0e, rgba(2, 2, 2, 0));
  .section-inner {
    margin: 3.5rem auto 0 auto;
  }
}

.accordion-item {
  border-color: $background_dark2;
  background-color: transparent;
  border-radius: 15px !important;
  margin: 10px 0;
  &:last-of-type {
    border-radius: 15px;
  }
  .accordion-button {
    background: rgba($color: #000000, $alpha: 0.5);
    color: white;
    border-radius: 15px !important;
    box-shadow: none;
    font-size: 17px;
    &:focus {
      box-shadow: none;
    }
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-90deg);
    }
    &:not(.collapsed) {
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    &.collapsed {
      border-radius: 15px 15px 0 0 !important;
      background: linear-gradient(90deg, $primary, $primary);
      &::after {
        transform: rotate(0deg);
      }
    }
  }
  .accordion-body {
    animation: heightAnime 0.3s ease-in-out;
    overflow: hidden;
  }
  .accordion-body {
    background-color: $background_dark2;
    border-radius: 0 0 15px 15px;
    color: white;
    text-align: justify;
    p {
      font-size: 14px;
      list-style: 24px;
    }
  }
}
