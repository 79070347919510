.about-marketplace-section {
  background-image: url(../assets/images/bg/market-place-mockup.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  margin: 30px 0 80px 0;
  clip-path: polygon(
    calc(100% - 300px) 0,
    calc(100% - 200px) 50px,
    100% 50px,
    100% 100%,
    300px 100%,
    200px calc(100% - 50px),
    0 calc(100% - 50px),
    0 0
  );
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $primary, $alpha: 0.5);
  }
  .section-inner {
    position: relative;
    z-index: 10;
    padding: 100px 0;
  }
  .grid-text-content {
    width: 60%;
    margin-right: auto;
    text-align: left;
  }
  .page-title {
    .title-inner {
      text-align: left;
      margin: 0;
      h2 {
        user-select: none;
        color: white;
        span {
          background: linear-gradient(90deg, $secondary_2 40%, $accent);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: $titleFont;
          font-weight: 800;
        }
      }
    }
  }
  .description {
    margin-top: 20px;
    p {
      text-align: justify;
      font-size: 18px;
      color: white;
    }
  }
}

@media (max-width: 1400px) {
  .about-marketplace-section {
    .grid-text-content {
      width: 60%;
    }
  }
}
@media (max-width: 1199px) {
  .about-marketplace-section {
    .grid-text-content {
      width: 70%;
    }
  }
}
@media (max-width: 992px) {
  .about-marketplace-section {
    &::before {
      background-color: rgba($color: $primary, $alpha: 0.7);
    }
    .grid-text-content {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .about-marketplace-section {
    .page-title {
      .title-inner {
        text-align: center;
      }
    }
    .description {
      p {
        text-align: center;
      }
    }
    .btns-group {
      text-align: center;
    }
  }
}
