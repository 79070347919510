.text-description-section {
  margin: 100px 0 140px 0;
  .section-inner {
    margin-bottom: 35px;
  }
  h2 {
    font-size: 34px;
    color: white;
    margin-bottom: 1rem;
  }
  p {
    font-size: 16px;
    color: rgba($color: white, $alpha: 0.8);
    text-align: justify;
    a {
      color: $secondary_2;
      margin: 0 5px;
      font-weight: 600;
      &:hover {
        color: $secondary;
      }
    }
  }
}

@media (max-width: 992px) {
  .text-description-section {
    margin-top: 80px;
    h2 {
      font-size: 30px;
    }
  }
}
@media (max-width: 768px) {
  .text-description-section {
    margin-top: 70px;
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
@media (max-width: 575px) {
  .text-description-section {
    margin-top: 60px;
    h2 {
      font-size: 26px;
    }
    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
}
