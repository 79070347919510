.swapwidget-section {
  margin-top: 100px;
  .section-inner {
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: #292532;
    //   z-index: -1;
    //   clip-path: polygon(
    //     50px 0%,
    //     100% 0,
    //     100% calc(100% - 50px),
    //     calc(100% - 50px) 100%,
    //     0 100%,
    //     0 50px
    //   );
    // }
  }
  .image-content {
    position: relative;
    // top: -80px;
    .img-inner {
      width: 300px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .page-title {
    .title-inner {
      width: 100% !important;
      h2 {
        span {
          user-select: none;
          text-transform: uppercase;
          background: linear-gradient(90deg, $primary, $primary_2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .text-content {
    // padding: 50px 50px 20px 0;
    .desc {
      margin: 2rem 0;
      p {
        font-size: 18px;
        text-align: justify;
        color: rgba($color: white, $alpha: 0.8);
      }
    }
    .thumb {
      width: 250px;
      margin: 0 auto;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        right: -160px;
        bottom: 50px;
        width: 113px;
        height: 56px;
        background-image: url(../assets/images/arrow-right.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        animation: swipeinfiniti_left 1s ease-in-out infinite;
      }
      img {
        width: 100%;
      }
    }
  }
}

#babydogeswap-widget {
  overflow: hidden;
  padding-bottom: unset;
  position: relative;
  height: 630px;
  border-radius: 16px;
  min-width: 400px;
  max-width: 400px;
  margin: 0 auto;
  > iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0 !important;
    border: none;
  }
}

#close-button {
  display: flex;
  padding: 8px 20px;
  font-family: "Poppins", "Kanit";
  font-size: 1.5rem !important;
  z-index: 100;
  position: absolute;
}

.swap--widget {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  margin: 0px 20px 20px 0px;
  cursor: pointer;
}

.d-none {
  display: none;
}

@media only screen and (max-width: 600px) {
  .swap--widget {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .swapwidget-section {
    margin-top: 60px;
    .section-inner {
      &::before {
        height: 90%;
      }
    }
    .text-content {
      margin-bottom: 2rem;
      .thumb {
        width: 200px;
        &::before {
          background-image: url(../assets/images/arrow-down.svg);
          animation: swipeinfiniti_up 1s ease-in-out infinite;
          width: 50px;
          height: 102px;
          right: -50px;
          bottom: 0;
        }
      }
    }
  }
  #babydogeswap-widget {
    min-width: 500px;
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .swapwidget-section {
    .image-content {
      .img-inner {
        width: 300px;
      }
    }
  }
  #babydogeswap-widget {
    min-width: 100%;
    max-width: 100%;
  }
}
