.portfolio-section {
  background-image: url(../assets/images/bg/portfolio-bg-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 260px;
  width: 100%;
  .container {
    height: 100%;
  }
  .section-inner {
    height: 100%;
    @include displayFlex(flex, flex-end, space-between);
  }
  .user-image {
    position: relative;
    top: 80px;
    .img-inner {
      width: 140px;
      height: 140px;
      border-radius: 5px;
      overflow: hidden;
      border: 3px solid white;
      img {
        width: 100%;
      }
    }
    .name {
      h2 {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        margin-bottom: 0;
        margin-top: 15px;
        padding-left: 5px;
        @include displayFlex(flex, center, center);
        .icon {
          margin-left: 10px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          @include displayFlex(flex, center, center);
          font-size: 14px;
          background-color: $primary_2;
          color: white;
        }
      }
    }
  }
  .social-icons {
    padding-bottom: 20px;
    ul {
      @include displayFlex(flex, center, flex-end);
      li {
        margin: 0 5px;
        a {
          @include displayFlex(flex, center, center);
          width: 40px;
          height: 40px;
          background-color: rgba($color: white, $alpha: 0.2);
          color: white;
          font-size: 20px;
          &:hover {
            background-color: white;
            color: $background;
          }
        }
      }
    }
  }
}

.portfolio-description {
  margin-top: 100px;
  .desc {
    height: 80px;
    overflow: hidden;
    &.active {
      height: auto;
      overflow: unset;
    }
  }
  p {
    color: rgba($color: white, $alpha: 0.8);
  }
  .btn-see-more {
    background-color: transparent;
    font-size: 14px;
    padding: 5px 0;
    border-radius: 0;
    border: none;
    color: $secondary_2;
  }
}

.nft-market-section {
  margin: 30px 0 60px 0;
  .grid-layout {
    display: grid;
    grid-template-columns: auto 300px;
  }
  .grid-market {
    padding-right: 20px;
  }
  .grid-filter {
    .grid-inner {
      position: sticky;
      top: 30px;
    }
    .sort {
      display: grid;
      grid-template-columns: 90px auto;
      align-items: center;
      h6 {
        font-size: 16px;
        font-weight: 400;
        color: white;
        margin-bottom: 0;
        .fi {
          color: $textColor_3;
          margin-right: 5px;
          position: relative;
          top: 3px;
        }
      }
      .form-select {
        background-color: $background_dark2;
        border: 1px solid rgba($color: white, $alpha: 0.4);
        border-radius: 0;
        font-size: 14px;
        color: white;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        &:hover,
        &:focus {
          border-color: $secondary_2;
          box-shadow: none;
        }
      }
    }
  }
  .availability {
    margin-top: 20px;
    background-color: rgba($color: white, $alpha: 0.05);
    padding: 15px;
    h6 {
      font-size: 18px;
      font-weight: 600;
      color: white;
      margin-bottom: 20px;
    }
    .form-check {
      margin-bottom: 12px;
      .form-check-label {
        font-size: 14px;
        color: white;
      }
    }
    .badge {
      margin-left: 12px;
      font-size: 12px;
      background-color: transparent !important;
      padding: 4px;
      border-radius: 2px;
      border: 1px solid rgba($color: white, $alpha: 0.3);
      color: rgba($color: white, $alpha: 0.7);
    }
    .form-switch .form-check-input {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23aaa'/%3e%3c/svg%3e");
      &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      }
    }
  }
  .nav-pills {
    border-bottom: 1px solid rgba($color: white, $alpha: 0.1);
    .nav-link {
      background-color: transparent;
      margin-right: 30px;
      border-radius: 0;
      padding: 10px 0;
      border-bottom: 2px solid transparent;
      color: rgba($color: white, $alpha: 0.4);
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-bottom-color: white;
        color: white;
      }
    }
  }
  .tab-content {
    .tab-inner {
      margin-top: 2rem;
    }
  }
  .cards-list {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    .card-nft {
      padding-right: 10px;
    }
  }
  .msg {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: white, $alpha: 0.6);
  }
}

.card-nft {
  margin-bottom: 15px;
  .card-inner {
    border: 1px solid rgba($color: white, $alpha: 0.2);
    border-radius: 3px;
    display: block;
    transition: all 0.2s ease-in-out;
  }
  .thumb {
    padding: 10px;
    position: relative;
    a {
      display: block;
    }
    img {
      width: 100%;
      border-radius: 2px;
    }
  }
  .wallet-address {
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    padding: 2px 8px 2px 5px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    a {
      font-size: 13px;
      color: white;
      font-weight: 400;
      position: relative;
      padding-left: 16px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        width: 12px;
        height: 12px;
        background-color: $secondary_2;
        border-radius: 50%;
      }
    }
    &:hover {
      background-color: rgba($color: black, $alpha: 0.8);
    }
  }
  .info {
    padding: 10px 10px 20px 15px;
    .user-name {
      margin-bottom: 8px;
      text-transform: uppercase;
      @include displayFlex(flex, center, flex-start);
      a {
        @include textTruncate(1);
        font-size: 15px;
        font-weight: 500;
        color: rgba($color: white, $alpha: 0.7);
        &:hover {
          text-decoration: underline 1px solid $secondary_2 !important;
          color: $secondary_2;
        }
      }
      .icon {
        width: 14px;
        height: 14px;
        background-color: $primary_2;
        color: white;
        font-size: 10px;
        border-radius: 50%;
        margin-left: 5px;
        @include displayFlex(flex, center, center);
      }
    }
    .inscription {
      font-size: 15px;
      font-weight: 400;
      a {
        color: white;
        @include textTruncate(1);
        &:hover {
          text-decoration: underline 1px solid $secondary_2 !important;
          color: $secondary_2;
        }
      }
    }
  }
  .c-footer {
    border-top: 1px solid rgba($color: white, $alpha: 0.15);
    padding: 10px;
    background-color: $background_dark2;
    border-radius: 0 0 3px 3px;
    @include displayFlex(flex, center, space-between);
    min-height: 48px;
    h5 {
      font-size: 16px;
      font-weight: 400;
      color: white;
      span {
        text-transform: uppercase;
        margin-left: 3px;
        font-size: 14px;
        color: rgba($color: white, $alpha: 0.7);
      }
    }
    .date {
      font-size: 13px;
      font-weight: 400;
      color: white;
      @include displayFlex(flex, center, center);
      .fi {
        margin-right: 5px;
        color: rgba($color: white, $alpha: 0.7);
      }
    }
    .btn-payment {
      background-color: $primary;
      color: white;
      padding: 3px 8px;
      border: none;
      border-radius: 0;
      font-size: 14px;
      display: none;
      &:hover {
        background-color: $primary_2;
      }
    }
  }
  &:hover {
    .card-inner {
      background-color: $background_dark2;
      border-color: rgba($color: white, $alpha: 0.2);
    }
    .wallet-address {
      opacity: 1;
    }
    .c-footer {
      .date {
        display: none;
      }
      .btn-payment {
        display: block;
      }
    }
  }
}

@media (max-width: 1199px) {
  .nft-market-section .cards-list {
    grid-template-columns: 50% 50%;
  }
}
@media (max-width: 992px) {
  .portfolio-section {
    margin-top: 68px;
    height: 200px;
    .user-image {
      .img-inner {
        width: 110px;
        height: 110px;
      }
      .name h2 {
        font-size: 20px;
        .icon {
          width: 20px;
          height: 20px;
          font-size: 12px;
        }
      }
    }
  }
  .nft-market-section {
    .grid-layout {
      grid-template-columns: 100%;
    }
    .grid-market {
      order: 2;
      padding-right: 0;
    }
    .grid-filter {
      order: 1;
      margin-bottom: 20px;
    }
    .availability {
      h6 {
        font-size: 16px;
      }
      .form-check {
        .form-check-label {
          font-size: 13px;
        }
      }
      .badge {
        font-size: 10px;
      }
    }
    .cards-list {
      grid-template-columns: 33.33% 33.33% 33.33%;
      .card-nft {
        padding: 0 5px;
      }
    }
  }
  .card-nft {
    .info {
      .user-name {
        a {
          font-size: 14px;
        }
      }
      .inscription {
        font-size: 14px;
      }
    }
    .price {
      h5 {
        font-size: 14px;
      }
      .date {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 768px) {
  .portfolio-section {
    height: 180px;
    .user-image {
      top: 75px;
      .img-inner {
        width: 100px;
        height: 100px;
      }
    }
    .social-icons {
      padding-bottom: 10px;
      ul li {
        margin: 0 4px;
        a {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  .nft-market-section {
    .cards-list {
      grid-template-columns: 50% 50%;
    }
  }
}
@media (max-width: 575px) {
  .portfolio-section {
    .user-image {
      top: 70px;
      .img-inner {
        width: 80px;
        height: 80px;
      }
      .name h2 {
        font-size: 18px;
        .icon {
          width: 16px;
          height: 16px;
          font-size: 10px;
        }
      }
    }
    .social-icons {
      padding-bottom: 10px;
      ul li {
        margin: 0 4px;
        a {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  .portfolio-description {
    .desc {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .card-nft {
    .info {
      .user-name {
        a {
          font-size: 13px;
        }
      }
      .inscription {
        font-size: 13px;
      }
    }
    .price {
      h5 {
        font-size: 13px;
      }
      .date {
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 390px) {
  .nft-market-section {
    .cards-list {
      grid-template-columns: 100%;
      .card-nft {
        padding: 0;
      }
    }
  }
}
