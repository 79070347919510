.planet-video-section {
  position: relative;
  overflow: hidden;
  // background-color: rgba($color: $background_dark2, $alpha: 0.2);
  padding: 50px 0;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 300px;
    width: 100%;
    background: linear-gradient(135deg, #0000 18.75%, #bc6650 0 31.25%, #0000 0),
      repeating-linear-gradient(45deg, #bc6650 -6.25% 6.25%, #af5943 0 18.75%);
    background-size: 150px 150px;
    clip-path: polygon(0 25%, 100% 0%, 100% 75%, 0% 100%);
  }
  .cloud-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    z-index: 20;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(../assets/images/clouds/cloud-top.png);
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
    }
  }
  .section-inner {
    position: relative;
    width: 900px;
    margin: 0 auto;
  }
  .btn-action {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    @include displayFlex(none, center, center);
    &.active {
      display: flex;
    }
    .btn-play {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: rgba($color: white, $alpha: 1);
      border: none;
      color: $primary;
      font-size: 24px;
      position: relative;
      transition: all 0.3s ease-in-out;
      .fi {
        position: relative;
        top: 3px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: -15px;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        border: 2px dashed white;
        display: inline-block;
        animation: rotatePlant 20s linear infinite;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .video-content {
    position: relative;
    z-index: 10;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    img {
      width: 100%;
    }
    video,
    iframe {
      border: 3px solid white;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-radius: 10px;
    }
  }
}

.ytp-large-play-button {
  display: none !important;
}

@media (max-width: 1199px) {
  .planet-video-section {
    .cloud-top {
      height: 220px;
    }
  }
}
@media (max-width: 992px) {
  .planet-video-section {
    .cloud-top {
      height: 180px;
    }
    .section-inner {
      width: 80%;
    }
  }
}
@media (max-width: 768px) {
  .planet-video-section {
    .cloud-top {
      height: 130px;
    }
    .section-inner {
      width: 90%;
    }
    .video-content {
      video,
      iframe {
        border: 3px solid white;
      }
    }
  }
}
@media (max-width: 575px) {
  .planet-video-section {
    .cloud-top {
      height: 90px;
    }
  }
}
@media (max-width: 400px) {
  .planet-video-section {
    .cloud-top {
      height: 70px;
    }
  }
}
