@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Black.eot");
    src: url("../fonts/Barlow-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Black.woff2") format("woff2"), url("../fonts/Barlow-Black.woff") format("woff"),
        url("../fonts/Barlow-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Thin.eot");
    src: url("../fonts/Barlow-Thin.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Thin.woff2") format("woff2"), url("../fonts/Barlow-Thin.woff") format("woff"),
        url("../fonts/Barlow-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Light.eot");
    src: url("../fonts/Barlow-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Light.woff2") format("woff2"), url("../fonts/Barlow-Light.woff") format("woff"),
        url("../fonts/Barlow-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Medium.eot");
    src: url("../fonts/Barlow-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Medium.woff2") format("woff2"), url("../fonts/Barlow-Medium.woff") format("woff"),
        url("../fonts/Barlow-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Regular.eot");
    src: url("../fonts/Barlow-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Regular.woff2") format("woff2"), url("../fonts/Barlow-Regular.woff") format("woff"),
        url("../fonts/Barlow-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-SemiBold.eot");
    src: url("../fonts/Barlow-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-SemiBold.woff2") format("woff2"), url("../fonts/Barlow-SemiBold.woff") format("woff"),
        url("../fonts/Barlow-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-ExtraLight.eot");
    src: url("../fonts/Barlow-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-ExtraLight.woff2") format("woff2"), url("../fonts/Barlow-ExtraLight.woff") format("woff"),
        url("../fonts/Barlow-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-ExtraBold.eot");
    src: url("../fonts/Barlow-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-ExtraBold.woff2") format("woff2"), url("../fonts/Barlow-ExtraBold.woff") format("woff"),
        url("../fonts/Barlow-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Barlow";
    src: url("../fonts/Barlow-Bold.eot");
    src: url("../fonts/Barlow-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Barlow-Bold.woff2") format("woff2"), url("../fonts/Barlow-Bold.woff") format("woff"),
        url("../fonts/Barlow-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
