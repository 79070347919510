.application-screenshots-section {
  position: relative;
  overflow: hidden;
  margin: 80px 0;
  .col-padding {
    padding-right: 0;
  }
  .text-contnet {
    position: relative;
    padding: 40px 0 40px 40px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 70vw;
      height: 100%;
      transform: translateY(-50%);
      background: linear-gradient(
        90deg,
        rgba(107, 83, 246, 0.1),
        rgba(107, 83, 246, 0)
      );
    }
    h1 {
      user-select: none;
      color: white;
      span {
        background: linear-gradient(90deg, $primary 40%, $primary_2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $titleFont;
        font-weight: 800;
      }
    }
    p {
      font-size: 18px;
      color: rgba($color: white, $alpha: 0.8);
      margin-top: 1.5rem;
      text-align: justify;
    }
  }
  .image-content {
    position: relative;
    right: -170px;
    .swiper-slide {
      transition: all 0.3s ease-in-out;
      opacity: 0 !important;
      overflow: unset !important;
      img {
        width: 100%;
      }
      &.swiper-slide-prev {
        opacity: 0 !important;
      }
      &.swiper-slide-active,
      &.swiper-slide-next {
        opacity: 1 !important;
      }
    }
  }
  &.type-align-left {
    .col-padding {
      padding-right: 15px;
      padding-left: 0;
    }
    .image-content {
      right: unset;
      left: -170px;
    }
    .swiper-appScreenshot {
      .swiper-button-next {
        left: unset;
        right: -150px;
      }
      .swiper-button-prev {
        left: unset;
        right: -90px;
      }
    }
  }
}

.swiper-appScreenshot {
  overflow: unset;
  .swiper-button-next,
  .swiper-button-prev {
    width: 48px;
    height: 48px;
    background-color: rgba($color: white, $alpha: 0.2);
    border-radius: 50%;
    top: unset;
    bottom: 20px;
    &::after {
      font-size: 24px;
      color: white;
    }
  }
  .swiper-button-next {
    right: unset;
    left: -90px;
  }
  .swiper-button-prev {
    right: unset;
    left: -150px;
  }
}

@media (max-width: 992px) {
  .application-screenshots-section {
    .text-contnet {
      margin: 0 0 3rem 0;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 16px;
      }
    }
    .image-content {
      width: 600px;
      margin-left: auto;
    }
    &.type-align-left {
      .image-content {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .application-screenshots-section {
    .text-contnet {
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    .image-content {
      width: 550px;
    }
  }
}
@media (max-width: 557px) {
  .application-screenshots-section {
    .text-contnet {
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 12px;
      }
      .buttons-group {
        .btn {
          width: 100%;
          font-size: 13px;
        }
      }
    }
    .image-content {
      width: 100%;
      right: -120px;
    }
    &.type-align-left {
      .swiper-appScreenshot {
        .swiper-button-next {
          right: -120px;
        }
        .swiper-button-prev {
          right: -70px;
        }
      }
    }
  }
  .swiper-appScreenshot {
    .swiper-button-next,
    .swiper-button-prev {
      width: 38px;
      height: 38px;
      &::after {
        font-size: 20px;
      }
    }
    .swiper-button-next {
      right: unset;
      left: -70px;
    }
    .swiper-button-prev {
      right: unset;
      left: -120px;
    }
  }
}

@media (max-width: 400px) {
  .application-screenshots-section {
    .image-content {
      right: -90px;
    }
    &.type-align-left {
      .image-content {
        left: -90px;
      }
      .swiper-appScreenshot {
        .swiper-button-next {
          right: -90px;
        }
        .swiper-button-prev {
          right: -45px;
        }
      }
    }
  }
  .swiper-appScreenshot {
    .swiper-button-next,
    .swiper-button-prev {
      width: 32px;
      height: 32px;
      &::after {
        font-size: 18px;
      }
    }
    .swiper-button-next {
      right: unset;
      left: -45px;
    }
    .swiper-button-prev {
      right: unset;
      left: -90px;
    }
  }
}
