.referral-hero-home {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
        180deg,
        rgba(30, 28, 32, 0) 0%,
        rgba(29, 25, 39, 1) 100%
      ),
      linear-gradient(
        180deg,
        rgba(255, 145, 103, 0.2) 0%,
        rgba(107, 83, 246, 0.2) 100%
      );
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 450px;
    background-image: url(../assets/images/referral/starts.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.7;
    animation: blickanim 3s ease-in-out infinite;
  }
  .section-inner {
    padding: 130px 0 40px 0;
  }
  .ufo-image-content {
    position: relative;
    z-index: 1;
    animation: bounceUpDown 1.2s ease-in-out infinite;
    .img-inner {
      width: 300px;
      margin: 0 auto;
    }
    img {
      width: 100%;
    }
  }
  .text-content {
    position: relative;
    top: -100px;
    z-index: 20;
    width: 700px;
    margin: 0 auto;
    text-align: center;
    h1 {
      color: white;
      font-size: 38px;
      margin-bottom: 25px;
      text-transform: uppercase;
    }
    p {
      color: white;
      margin-bottom: 25px;
      font-size: 18px;
      strong {
        color: $primary;
      }
    }
  }
}

.how-invite-section {
  margin-bottom: 30px;
  // position: relative;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   bottom: -130px;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(../assets/images/referral/stars-wave.png);
  //   background-repeat: repeat-x;
  //   background-position: center bottom;
  //   background-size: 450px;
  //   opacity: 0.2;
  //   z-index: -1;
  // }
  .section-inner {
    margin-top: 3rem;
  }
  .col-md-6 {
    flex-grow: 1;
  }
}

.get-rewards-section {
  padding-bottom: 120px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/referral/wave.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    opacity: 1;
    z-index: -1;
  }
  .col-md-6 {
    flex-grow: 1;
  }
}

@media (max-width: 1199px) {
}

@media (max-width: 992px) {
  .referral-hero-home {
    &::after {
      height: 400px;
    }
    .ufo-image-content {
      .img-inner {
        width: 250px;
      }
    }
    .text-content {
      top: -80px;
      h1 {
        font-size: 34px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .referral-hero-home {
    &::after {
      height: 350px;
    }
    .ufo-image-content {
      .img-inner {
        width: 200px;
      }
    }
    .text-content {
      width: 100%;
      top: -60px;
      h1 {
        font-size: 30px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 575px) {
  .referral-hero-home {
    &::after {
      height: 300px;
    }
    .section-inner {
      padding: 100px 0 60px 0;
    }
    .ufo-image-content {
      .img-inner {
        width: 150px;
      }
    }
    .text-content {
      top: -40px;
      h1 {
        font-size: 26px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}
