/*#region ----------------- Header */
.navbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 500;
  &.have-bg {
    background-color: $background_dark2;
  }
  .nav-inner {
    padding: 10px 0;
    display: grid;
    grid-template-columns: 200px auto 200px;
    align-items: center;
    width: 100%;
  }
  .nav-logo {
    width: 180px;
    a {
      display: block;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .nav-links {
    ul {
      @include displayFlex(flex, center, center);
      li {
        position: relative;
        &:hover {
          .sub-links {
            display: block;
            animation: swipeUpsmall 0.3s ease-in-out;
          }
        }
      }
      a {
        display: block;
        padding: 10px 15px;
        color: $textColor_1;
        font-weight: 600;
        transition: all 0.3s ease 0s;
        &:hover {
          color: $primary;
        }
        .fi {
          margin-left: 5px;
          position: relative;
          top: 2px;
        }
      }
    }
    .sub-links {
      position: absolute;
      top: 100%;
      left: 0;
      width: 200px;
      display: block;
      background-color: rgba($color: white, $alpha: 1);
      border-radius: 10px;
      padding: 10px 0;
      display: none;
      li {
        display: block;
        a {
          font-size: 14px;
          text-transform: capitalize;
          color: rgba($color: #000000, $alpha: 0.7);
          transition: all 0.2s ease-in-out;
        }
        &:hover {
          a {
            color: $primary;
            padding-left: 20px;
          }
        }
      }
    }
  }
  .nav-sign {
    margin-left: auto;
  }
  .mobile-menu {
    display: none;
  }
}

.btn-mobile-menu {
  background-color: transparent;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  color: $textColor_1;
  border: none;
  font-size: 24px;
  @include displayFlex(flex, center, center);
  margin-right: auto;
}

#sidebar-menu {
  position: fixed;
  top: 0;
  left: -300px;
  height: 100vh;
  width: 300px;
  z-index: 2002;
  background: $background;
  overflow-y: auto;
  transition: all 0.3s;
  &.active {
    left: 0;
  }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 12px;
    .sidebar-close {
      position: absolute;
      right: 10px;
      top: 10px;
      .btn-close-menu {
        width: 45px;
        height: 45px;
        color: white;
        background-color: transparent;
        border: none;
        padding: 0;
        @include displayFlex(flex, center, center);
        &:hover {
          color: $primary;
          .fi {
            color: white;
          }
        }
      }
    }
    .sidebar-logo {
      a {
        display: block;
        text-align: center;
        img {
          width: 160px;
        }
      }
    }
  }
  .category-list {
    margin-top: 30px;
    .menu-links {
      .link {
        position: relative;
        a {
          padding: 10px 10px 10px 20px;
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: $textColor_1;
          position: relative;
          transition: all 0.2s ease;
          .icon {
            @include displayFlex(flex, center, center);
            position: absolute;
            right: 5px;
            top: 0;
            width: 44px;
            height: 44px;
          }
          .fi {
            color: rgba($color: white, $alpha: 0.6);
          }
          &:hover {
            color: $secondary_2;
            padding-left: 25px;
            transition: all 0.2s ease;
          }
        }
        &.active {
          a {
            .icon {
              transform: rotate(90deg);
            }
          }
        }
      }
      .sub-menu-links {
        background-color: rgba($color: white, $alpha: 0.05);
        padding: 10px;
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  .social-icons {
    border-top: 1px solid $border;
    margin-top: 2rem;
    padding-top: 2rem;
    ul {
      @include displayFlex(flex, center, center);
      a {
        @include displayFlex(flex, center, center);
        padding: 10px;
        font-size: 24px;
        color: $textColor_2;
        &:hover {
          color: $primary;
          .fi {
            color: $primary;
          }
          svg {
            fill: $primary;
          }
        }
      }
      .fi {
        line-height: 1 !important;
        color: $textColor_2;
      }
      svg {
        fill: $textColor_2;
        width: 28px;
      }
    }
  }
}
.overlay-back {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2001;
  opacity: 0;
  transition: all 0.3s;
  &.active {
    display: block;
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .navbar {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    background-color: $background_dark2;
    box-shadow: 0 5px 20px -1px rgba($color: $textColor_3, $alpha: 0.1);
    z-index: 900;
    .nav-inner {
      grid-template-columns: 48px auto 48px;
      padding: 0;
    }
    .nav-logo {
      text-align: center;
      margin: 0 auto;
      a {
        display: inline-block;
        width: 130px;
      }
    }
    .nav-links {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
    .nav-sign {
      .sign {
        .btn-accent {
          padding: 5px;
          width: 44px;
          height: 44px;
          .icon {
            @include displayFlex(flex, center, center);
            height: 100%;
            width: 100%;
            .fi {
              right: 0;
            }
            &::before {
              display: none;
            }
          }
          .text {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .navbar {
    .nav-logo {
      width: 100%;
    }
  }
}
@media (max-width: 350px) {
  #sidebar-menu {
    width: 100%;
    left: -350px;
  }
}

/*#endregion -------------- Header */
