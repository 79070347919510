.contactus-section {
  margin: 10rem 0;
  .section-inner {
    width: 900px;
    margin: 3rem auto 0 auto;
    background-color: $backgroundLight;
    padding: 50px;
    border-radius: 25px;
    box-shadow: inset 0 0 30px rgba(0, 17, 136, 0.2);
  }
  .inputgroup {
    label {
      color: $background;
    }
    .input {
      border: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
  }
  .select-group {
    label {
      color: $background;
    }
    .form-select {
      border: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
  }
  .buttons-group {
    margin-top: 25px;
    text-align: center;
    .btn-primary {
      min-width: 200px;
    }
  }
}

@media (max-width: 992px) {
  .contactus-section {
    margin: 8rem 0 10rem 0;
    .section-inner {
      width: 100%;
      margin: 3rem auto 0 auto;
      padding: 40px;
    }
  }
}
@media (max-width: 768px) {
  .contactus-section {
    .section-inner {
      padding: 30px;
      margin: 2rem auto 0 auto;
    }
  }
}
@media (max-width: 575px) {
  .contactus-section {
    .section-inner {
      padding: 20px;
    }
  }
}
