.token-section {
  padding: 60px 0;
  .page-title {
    .title-inner {
      width: 100% !important;
      h2 {
        span {
          user-select: none;
          text-transform: uppercase;
          background: linear-gradient(90deg, $primary, $primary_2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .info {
    margin-top: 6rem;
    background-color: rgba($color: white, $alpha: 0.05);
    padding: 0 30px 30px 30px;
    display: grid;
    grid-template-columns: 210px auto;
    .percentage {
      background: linear-gradient(45deg, $secondary_2, $secondary);
      padding: 30px;
      text-align: center;
      margin-right: auto;
      margin-top: -50px;
      @include displayFlex(flex, center, center);
      flex-direction: column;
      min-width: 180px;
      clip-path: polygon(
        50px 0%,
        100% 0,
        100% calc(100% - 50px),
        calc(100% - 50px) 100%,
        0 100%,
        0 50px
      );
      h2 {
        font-size: 50px;
        font-weight: 800;
        color: white;
        line-height: normal;
      }
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: rgba($color: white, $alpha: 0.8);
      }
    }
    .total-supply {
      padding-top: 1.5rem;
      h4 {
        color: rgba($color: white, $alpha: 0.8);
        font-weight: 400;
      }
      h3 {
        color: white;
      }
    }
  }
  .address {
    background-color: rgba($color: $primary, $alpha: 1);
    padding: 20px 30px;
    h5 {
      color: white;
      margin-bottom: 0;
      font-weight: 400;
    }
    a {
      display: inline-block;
      margin-top: 10px;
      font-size: 16px;
      color: white;
    }
  }
  .image-content {
    .img-inner {
      width: 80%;
      margin: 0 auto;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/img-back-bulb.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: -1;
        opacity: 0.2;
      }
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1400px) {
  .token-section {
    .info {
      grid-template-columns: 170px auto;
      .percentage {
        padding: 20px;
        min-width: 150px;
        clip-path: polygon(
          40px 0%,
          100% 0,
          100% calc(100% - 40px),
          calc(100% - 40px) 100%,
          0 100%,
          0 40px
        );
        h2 {
          font-size: 55px;
        }
        .desc {
          font-size: 18px;
        }
      }
      .total-supply {
        padding-top: 1.2rem;
        h4 {
          font-size: 24px;
        }
        h3 {
          font-size: 30px;
        }
      }
    }
    .address {
      h5 {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 1199px) {
}
@media (max-width: 992px) {
  .token-section {
    padding: 50px 0;
    .image-content {
      margin-top: 2rem;
      .img-inner {
        width: 350px;
      }
    }
  }
}
@media (max-width: 768px) {
  .token-section {
    padding: 40px 0;
    .info {
      grid-template-columns: 140px auto;
      padding: 0 20px 30px 20px;
      .percentage {
        padding: 15px;
        min-width: 120px;
        clip-path: polygon(
          35px 0%,
          100% 0,
          100% calc(100% - 35px),
          calc(100% - 35px) 100%,
          0 100%,
          0 35px
        );
        h2 {
          font-size: 40px;
        }
        .desc {
          font-size: 18px;
        }
      }
      .total-supply {
        padding-top: 1rem;
        h4 {
          font-size: 20px;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
    .address {
      padding: 15px 20px;
      h5 {
        font-size: 18px;
      }
      a {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 575px) {
  .token-section {
    .info {
      grid-template-columns: 120px auto;
      padding: 0 10px 20px 10px;
      .percentage {
        padding: 10px;
        min-width: 110px;
        clip-path: polygon(
          35px 0%,
          100% 0,
          100% calc(100% - 35px),
          calc(100% - 35px) 100%,
          0 100%,
          0 35px
        );
        h2 {
          font-size: 34px;
        }
        .desc {
          font-size: 16px;
        }
      }
      .total-supply {
        padding-top: 1rem;
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 20px;
        }
      }
    }
    .address {
      padding: 10px 15px;
      h5 {
        font-size: 16px;
      }
      a {
        font-size: 13px;
      }
    }
    .image-content {
      .img-inner {
        width: 90%;
      }
    }
  }
}
