.developers-section {
  position: relative;
  user-select: none;
  // background-color: rgba($color: $background_dark2, $alpha: 0.2);
  // margin-bottom: 100px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $primary;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: rgba($color: $background_dark2, $alpha: 0.2);
    z-index: -1;
  }
  .section-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .container {
    height: 100%;
  }
  .page-title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .title-inner {
      width: 100%;
      text-align: center;
    }
    h2 {
      span {
        background: linear-gradient(90deg, $secondary 40%, $secondary_2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $titleFont;
      }
    }
  }
  .video-content {
    position: relative;
    height: 450px;
    overflow: hidden;
    clip-path: polygon(100% 0%, 100% 0, 100% 100%, 50% 90%, 0 100%, 0 0);
    video,
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        $card_background_1,
        $card_background_2
      );
      z-index: 10;
      opacity: 0.9;
    }
  }
}
