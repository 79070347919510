.wallet-section {
  margin: 2rem 0 5rem 0;
  .user-profile {
    @include displayFlex(flex, center, flex-start);
    position: relative;
    .user-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $secondary;
      img {
        padding: 10px;
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
        border: 3px solid white;
      }
    }
    .user-name {
      margin-left: 10px;
    }
    h6 {
      color: $textColor_1;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.wallet-side-menu {
  padding: 1.2rem 0;
  ul li {
    margin: 5px 0;
    a {
      font-size: 16px;
      font-weight: 500;
      color: $textColor_1;
      padding: 12px;
      display: block;
      transition: all 0.2s ease-in-out;
      border-radius: 15px;
    }
    i {
      font-size: 20px;
      margin-right: 10px;
      color: $textColor_2;
      position: relative;
      top: 3px;
    }
    &:hover {
      a,
      i {
        color: $primary;
      }
    }
    &.active {
      a {
        font-weight: 700;
        background: linear-gradient(90deg, $secondary, $primary);
        &:hover {
          color: $textColor_1;
        }
      }
      i {
        color: $textColor_1;
      }
    }
  }
}
.wallet-main {
  padding-left: 40px;
  border-left: 1px solid $border;
  display: none;
  &.active {
    display: block;
  }
}

.wallet-title {
  font-size: 22px;
  font-weight: 800;
  color: $textColor_1;
  margin-bottom: 5px;
}
.wallet-desc {
  font-size: 16px;
  color: $textColor_2;
}

.shopping-table-section {
  margin-top: 2rem;
}

.referral-code {
  .code-inner {
    display: grid;
    grid-template-columns: auto 300px;
    align-items: flex-end;
    background-color: $background_dark2;
    padding: 25px;
    border-radius: 15px;
    margin-top: 1rem;
    position: relative;
  }
  .gift-image {
    position: absolute;
    width: 180px;
    height: 180px;
    right: 0;
    top: -150px;
    img {
      width: 100%;
      border-radius: 50%;
      border: 3px solid white;
    }
    video,
    iframe {
      width: 100%;
    }
  }
  .code-info {
    padding: 30px 20px 0 10px;
    .desc {
      font-size: 14px;
      font-weight: 500;
      color: $textColor_1;
      text-align: justify;
    }
    .code {
      margin-top: 1rem;
      position: relative;
      border: 2px dashed $primary;
      background-color: rgba($color: #fff, $alpha: 0.1);
      border-radius: 10px;
      padding: 14px 75px 14px 10px;
      h6 {
        color: $textColor_1;
        text-transform: uppercase;
      }
      .btn-copy,
      .btn-setting {
        position: absolute;
        top: 0;
        background-color: transparent;
        color: white;
        font-size: 22px;
        padding: 2px;
        border: none;
        width: 35px;
        height: 50px;
        @include displayFlex(flex, center, center);
        &:hover {
          color: $primary_2;
        }
      }
      .btn-copy {
        right: 35px;
      }
      .btn-setting {
        right: 0;
      }
    }
  }
  .clam {
    border-left: 1px solid rgba($color: white, $alpha: 0.2);
    height: 100%;
    padding: 15px 15px 0 15px;
    text-align: center;
    @include displayFlex(flex, center, space-between);
    flex-direction: column;
    .money {
      padding: 10px 0;
      h2 {
        color: white;
        font-size: 26px;
      }
    }
    .btn-primary {
      width: 100%;
      height: 52px;
    }
  }
}

@media (max-width: 1199px) {
  .wallet-section .user-profile {
    .user-image {
      width: 80px;
      height: 80px;
    }
    h6 {
      font-size: 18px;
    }
  }
  .referral-code .code-inner {
    grid-template-columns: auto 250px;
  }
}

@media (max-width: 992px) {
  .wallet-side-menu {
    padding-bottom: 20px;
    padding-left: 0;
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        padding-right: 10px;
        &:last-child {
          border-right: none;
        }
        a {
          padding: 10px 15px 10px 10px;
          border-radius: 12px;
        }
      }
    }
  }
  .wallet-main {
    padding-left: 0;
    padding-top: 25px;
    border-left: 0;
    border-top: 1px solid rgba(215, 215, 216, 0.1);
  }
  .referral-code .gift-image {
    width: 150px;
    height: 150px;
    top: -110px;
  }
}

@media (max-width: 768px) {
  .wallet-title {
    font-size: 20px;
  }
  .referral-code {
    .code-inner {
      @include displayFlex(flex, center, center);
      flex-direction: column;
      padding-top: 0;
    }
    .gift-image {
      position: relative;
      top: 0;
    }
    .code-info {
      flex: 0 0 100%;
      width: 100%;
      padding: 25px 0 0 0;
    }
    .clam {
      flex: 0 0 100%;
      width: 100%;
      border: none;
      padding-left: 0;
      padding-right: 0;
      .money {
        padding: 0 0 15px 0;
      }
    }
  }
}
@media (max-width: 575px) {
  .wallet-section .user-profile {
    .user-image {
      width: 70px;
      height: 70px;
    }
    h6 {
      font-size: 16px;
    }
  }
  .wallet-title {
    font-size: 18px;
  }
}
