.page-title {
  .title-inner {
    width: 700px;
    margin: 0 auto;
    text-align: center;
  }
  h2 {
    color: white;
    text-transform: uppercase;
  }
  p {
    color: rgba($color: white, $alpha: 0.8);
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 18px;
  }
  &.align-left {
    .title-inner {
      width: 100%;
      margin: 0;
      text-align: left;
    }
  }
}

@media (max-width: 768px) {
  .page-title {
    .title-inner {
      width: 100%;
    }
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 16px;
    }
  }
}
@media (max-width: 575px) {
  .page-title {
    .title-inner {
      width: 100%;
    }
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 14px;
    }
  }
}
