.partner-section {
  padding: 50px 0 100px 0;
  position: relative;
  .plant {
    width: 350px;
    height: 200px;
    margin: 0 auto;
    position: relative;
    top: -60px;
    z-index: 100;
    video,
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &.backers {
    background-color: rgba(2, 2, 2, 0.2);
  }
  &.included {
    background-color: rgba(107, 83, 246, 0.3);
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 100px),
      calc(100% - 100px) 100%,
      0 100%
    );
    padding: 60px 0;
    background-image: url(../assets/images/shft-piskel.svg);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center left;
    .section-inner {
      @include displayFlex(flex, center, center);
      margin: 0;
      h2 {
        font-size: 30px;
        margin: 0 10px 0 0;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .img-content {
      width: 220px;
      img {
        width: 100%;
      }
    }
  }
  .section-inner {
    margin-top: 3rem;
  }
  .text-big-partner {
    font-size: 18px;
    font-weight: 500;
    background: linear-gradient(90deg, $secondary 20%, $secondary_2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $titleFont;
    font-weight: 800;
  }
  .social-icons {
    ul {
      border-radius: 20px;
      @include displayFlex(flex, center, flex-start);
      li {
        a {
          width: 40px;
          height: 40px;
          background-color: rgba($color: white, $alpha: 0.2);
          margin: 5px;
          border-radius: 50%;
          @include displayFlex(flex, center, center);
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: $secondary_2;
            i {
              color: white;
            }
          }
        }
        i {
          color: white;
          font-size: 20px;
        }
      }
    }
  }
  .image-content {
    .img-inner {
      width: 70%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 992px) {
  .partner-section {
    .image-content {
      margin-bottom: 20px;
      .img-inner {
        width: 250px;
      }
    }
    .backers-content {
      .row {
        justify-content: center;
      }
    }
    .social-icons {
      ul {
        justify-content: center;
      }
    }
    &.backers {
      .page-title {
        .title-inner {
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .partner-section {
    padding-top: 0;
    padding-bottom: 80px;
    .plant {
      top: -40px;
    }
    &.included {
      background-size: 90px;
      .img-content {
        width: 180px;
      }
      .section-inner {
        h2 {
          font-size: 28px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .partner-section {
    padding-bottom: 50px;
    .plant {
      width: 250px;
      height: 200px;
    }
    &.included {
      background-size: 70px;
      .img-content {
        width: 150px;
      }
      .section-inner {
        flex-direction: column;
        h2 {
          margin: 0;
          font-size: 26px;
        }
      }
    }
  }
}
