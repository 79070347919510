.advisors-section {
  margin: 40px 0 0 0;
  .section-inner {
    background-color: #14111b;
    padding: 50px;
  }
  .page-title {
    .title-inner {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        // background-color: $primary;
        background: linear-gradient(90deg, $secondary, $secondary_2);
        // z-index: -1;
      }
    }
    h2 {
      background-color: #14111b;
      position: relative;
      display: inline-block;
      padding-right: 30px;
    }
  }
  .cards-list {
    margin-top: 3rem;
    @include displayFlex(flex, center, center);
    flex-wrap: wrap;
    .card-advisor {
      flex: 0 0 50%;
      width: 50%;
    }
  }
}

.card-advisor {
  padding-right: 20px;
  .card-inner {
    display: grid;
    grid-template-columns: 90px auto;
    align-items: center;
  }
  .thumb {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid white;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .info {
    padding-left: 15px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: rgba($color: white, $alpha: 0.8);
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
      font-size: 22px;
      font-weight: 600;
      color: white;
    }
  }
}

@media (max-width: 992px) {
  .advisors-section {
    .section-inner {
      padding: 40px;
    }
    .cards-list {
      padding-right: 0;
      .card-advisor {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .advisors-section {
    .section-inner {
      padding: 30px;
    }
  }
  .card-advisor {
    .card-inner {
      grid-template-columns: 70px auto;
    }
    .thumb {
      width: 70px;
      height: 70px;
    }
    .info {
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
