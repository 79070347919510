.games-section {
  padding: 100px 0;
  margin: 80px 0 120px 0;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $background_dark2, $alpha: 0.4);
    clip-path: polygon(
      calc(100% - 300px) 0,
      calc(100% - 200px) 50px,
      100% 50px,
      100% 100%,
      300px 100%,
      200px calc(100% - 50px),
      0 calc(100% - 50px),
      0 0
    );
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    top: -15px;
    right: 0;
    width: 290px;
    height: 50px;
    background-color: $primary;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100px 100%);
  }
  .page-title {
    .title-inner {
      h2 {
        span {
          user-select: none;
          text-transform: uppercase;
          background: linear-gradient(90deg, $secondary, $accent);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .section-inner {
    position: relative;
    width: 950px;
    margin: 3rem auto 0 auto;
  }
  .game-box {
    position: relative;
    margin-bottom: 20px;
    &.disabled {
      .image-content {
        img {
          filter: grayscale(1);
        }
      }
    }
  }
  .text-overlay {
    position: absolute;
    bottom: 1px;
    left: 1px;
    z-index: 100;
    background-color: rgba($color: $background_dark2, $alpha: 0.8);
    padding: 20px 120px 20px 30px;
    clip-path: polygon(0 0, calc(100% - 80px) 0, 100% 100%, 0% 100%);
    h3 {
      font-size: 22px;
      color: white;
      margin-bottom: 5px;
      text-transform: capitalize;
    }
    h6 {
      font-size: 16px;
      font-weight: 400;
      color: $primary;
      margin: 0;
      padding: 0.2rem 2.5rem 0.2rem 0;
    }
    .btn-link {
      padding: 0.2rem 2.5rem 0.2rem 0;
      border-radius: 0;
      color: $secondary_2;
      height: unset;
      .fi {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      &:hover {
        color: $secondary;
      }
      &.color-disabled {
        color: $primary;
      }
    }
    &.small {
      padding: 15px 100px 15px 30px;
      h3 {
        font-size: 18px;
        margin-bottom: 0;
      }
      .btn-link {
        font-size: 14px;
      }
    }
  }
  .image-content {
    border: 1px solid white;
    overflow: hidden;
    img {
      width: 100%;
      transition: all 1s ease-in-out;
    }
    &:hover {
      img {
        transform: rotate(-3deg) scale(1.1);
      }
    }
  }
  .shape-up {
    position: absolute;
    left: 0;
    top: -50px;
    width: 98px;
    height: 220px;
    background-image: url(../assets/images/game-shape.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .shape-down {
    position: absolute;
    right: 10px;
    bottom: -50px;
    width: 124px;
    height: 30px;
    background-image: url(../assets/images/shape-arrow-left.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.swiper-games {
  .slide-content {
    .img-content {
      padding-bottom: 1px;
      img {
        border: 1px solid white;
        width: 100%;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 48px;
    height: 48px;
    border-radius: 0;
    background-color: rgba($color: $background_dark2, $alpha: 0.8);
    &::after {
      font-size: 20px;
      color: white;
    }
    &:hover {
      background-color: $primary;
    }
  }
  .swiper-button-next {
    right: 1px;
    bottom: 1px;
    top: unset;
  }
  .swiper-button-prev {
    top: unset;
    left: unset;
    right: 50px;
    bottom: 1px;
  }
}

@media (max-width: 992px) {
  .games-section {
    .section-inner {
      width: 100%;
    }
    .shape-up {
      width: 70px;
      height: 157px;
    }
  }
}

@media (max-width: 768px) {
  .games-section {
    &::after {
      clip-path: polygon(
        calc(100% - 200px) 0,
        calc(100% - 100px) 50px,
        100% 50px,
        100% 100%,
        200px 100%,
        100px calc(100% - 50px),
        0 calc(100% - 50px),
        0 0
      );
    }
    &::before {
      content: "";
      position: absolute;
      top: -15px;
      right: 0;
      width: 190px;
      height: 50px;
      background-color: $primary;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 100px 100%);
    }
    .text-overlay {
      padding: 15px 70px 15px 20px;
      clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 100%, 0% 100%);
      h3 {
        font-size: 18px;
      }
      .btn-link {
        font-size: 13px;
      }
      &.small {
        clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 100%, 0% 100%);
        h3 {
          font-size: 18px;
        }
        .btn-link {
          font-size: 13px;
        }
      }
    }
    .shape-up {
      top: -70px;
    }
  }
  .swiper-games {
    .swiper-button-next,
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      &::after {
        font-size: 18px;
      }
    }
    .swiper-button-prev {
      right: 44px;
    }
  }
}
