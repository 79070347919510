.custom-select-container {
  position: relative;
  .select-header {
    display: grid;
    grid-template-columns: 30px auto 24px;
    align-items: center;
    background-color: rgba($color: white, $alpha: 0.2);
    color: white;
    border-radius: 10px;
    padding: 0.5rem 0.3rem 0.5rem 0.5rem;
    min-height: 44px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    .icon {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .title {
      font-size: 13px;
      font-weight: 600;
      padding: 0 5px;
    }
    .arrow-down {
      width: 24px;
      height: 24px;
      @include displayFlex(flex, center, flex-start);
      i {
        line-height: 0;
      }
    }
  }
  .select-list {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background-color: rgba($color: $background, $alpha: 0.8);
    padding: 10px 5px;
    border-radius: 10px;
    display: none;
    max-height: 212px;
    overflow-y: auto;
    z-index: 50;
    &.active {
      display: block;
    }
    .item {
      display: grid;
      grid-template-columns: 30px auto;
      align-items: center;
      padding: 5px;
      user-select: none;
      border-radius: 8px;
      cursor: pointer;
      .icon {
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .title {
        padding-left: 10px;
        font-size: 13px;
        font-weight: 500;
        color: white;
      }
      &:hover {
        background-color: rgba($color: white, $alpha: 0.1);
      }
    }
  }
}
