.btn {
  padding: 0.65rem 1.2rem;
  border-radius: 2px;
  border: none;
  white-space: nowrap;
  position: relative;
  height: 50px;
  background-size: 0% 100%;
  overflow: hidden;
  line-height: 28px;
  i {
    font-size: 20px;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.5) 100%,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0) 0%
    );
    border-radius: 10px 0 0 10px;
    transition: all 0.2s ease-in-out;
  }
}

.icon-right {
  padding-right: 3.1rem;
  .icon {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0;
    top: 0;
    @include displayFlex(flex, center, center);
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      right: 5px;
      top: 4px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      z-index: -1;
    }
    .fi {
      position: relative;
      top: 1px;
    }
  }
}
.icon-left {
  padding-left: 3.1rem;
  .icon {
    position: absolute;
    width: 48px;
    height: 48px;
    left: 0;
    top: 0;
    @include displayFlex(flex, center, center);
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 4px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      z-index: -1;
    }
    .fi {
      position: relative;
      left: 2px;
    }
  }
}

.btn-primary {
  background: $primary;
  i {
    color: white;
  }
  &:hover,
  &:focus {
    box-shadow: none;
    background-color: $primary_2;
    .icon {
      &::before {
        transform: rotate(0);
        border-radius: 50%;
      }
    }
    &::after {
      width: 100%;
      opacity: 0.2;
    }
  }
  &.color-1 {
    background: linear-gradient(90deg, #ff9a4a, #ffeaa4);
    color: black;
  }
  &.color-2 {
    background: linear-gradient(90deg, #550094, #ba80e4);
  }
  &.color-3 {
    background: linear-gradient(90deg, #29329c, #939bfc);
  }
  &.color-4 {
    background: linear-gradient(90deg, #090076, #746eca);
  }
}

.btn-secondary {
  background: $secondary_2;
  i {
    color: white;
  }
  &:hover,
  &:focus {
    box-shadow: none;
    background-color: $secondary;
    .icon {
      &::before {
        transform: rotate(0);
        border-radius: 50%;
      }
    }
    &::after {
      width: 100%;
      opacity: 0.2;
    }
  }
}

.btn-primary-outline {
  background-color: transparent;
  border-color: $primary;
  color: $primary;
  &:hover,
  &:focus {
    border-color: $error;
    color: $error;
  }
}

.btn-accent {
  background-color: white;
  color: $primary;
  i {
    color: $primary;
  }
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: white;
    background-color: white;
    .icon {
      &::before {
        transform: rotate(0);
        border-radius: 50%;
      }
    }
    &::after {
      width: 100%;
      opacity: 0.2;
      background: linear-gradient(
        to right,
        rgba(107, 83, 246, 0.5) 100%,
        rgba(107, 83, 246, 0) 40%,
        rgba(107, 83, 246, 0) 0%
      );
    }
  }
}

.btn-cta {
  background-color: $secondary_2;
  border-radius: 0;
  min-width: 180px;
  overflow: hidden;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 1rem 2rem;
  color: white;
  border: none;
  white-space: nowrap;
  line-height: 28px;
  display: inline-block;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  &::before {
    content: "";
    position: absolute;
    background-color: rgba($color: white, $alpha: 0.3);
    height: 5.25rem;
    width: 15.5rem;
    transform: translate3d(-120%, -2.125rem, 0) rotate3d(0, 0, 1, 45deg);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    background-color: rgba($color: white, $alpha: 0.9);
    transition: all 2s ease-in-out;
    z-index: 10;
    animation: lineMove 2s ease-in-out infinite;
  }
  &:hover {
    color: white;
    transform: scale(1.05);
    border-radius: 15px;
    &::before {
      background-color: white;
      transform: translate3d(120%, -6.25rem, 0) rotate3d(0, 0, 1, 90deg);
    }
  }
}

@keyframes lineMove {
  0% {
    left: 10px;
    top: 0;
    height: 100%;
    width: 4px;
    transform: translateY(-65px);
    opacity: 1;
  }
  25% {
    transform: translateY(65px);
    opacity: 0;
  }
  40% {
    left: unset;
    right: 10px;
    top: 0;
    height: 100%;
    width: 4px;
    transform: translateY(65px);
    opacity: 1;
  }
  80% {
    transform: translateY(-65px);
    opacity: 0;
  }
}
