.card-ecosystem {
  perspective: 1000px;
  margin: 12px 0;
  user-select: none;
  .card-inner {
    background: rgba($color: $textColor_4, $alpha: 0.2);
    transform-style: preserve-3d;
    transition: transform 0.6s;
    border-radius: 2px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .thumb {
    width: 200px;
    height: 200px;
    margin: 0 auto 1rem auto;
    transition: all 0.3s ease-in-out;
    @include displayFlex(flex, center, center);
    img {
      width: 100%;
    }
    video,
    iframe {
      width: 100%;
    }
  }
  .info {
    text-align: center;
    .name {
      font-size: 18px;
      color: white;
      margin-bottom: 10px;
    }
    .desc {
      overflow-y: auto;
      height: calc(100% - 80px);
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      color: rgba($color: white, $alpha: 0.8);
    }
  }
  .btn-link {
    font-size: 14px;
    border: 1px solid rgba($color: white, $alpha: 0.4);
    border-radius: 2px;
    color: white;
    display: inline-block;
    padding: 8px 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    &:hover {
      background-color: $secondary_2;
      color: white;
    }
  }
  .arrow-back {
    position: absolute;
    left: 10px;
    bottom: 10px;
    transform: rotate(-180deg);
    i {
      font-size: 20px;
      color: rgba($color: white, $alpha: 0.1);
    }
  }
  .card-front {
    padding: 0 20px 35px 20px;
    transition: all 0.3s ease-in-out;
    .info {
      p {
        @include textTruncate(1);
      }
    }
  }
  .card-back {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(180deg);
    padding: 20px;
    .info {
      height: 100%;
      text-align: left;
    }
  }
  br {
    content: "";
    margin: 1em;
    display: block;
    font-size: 24%;
  }
  &:hover {
    .card-inner {
      transform: rotateY(180deg);
    }
    .thumb {
      transform: scale(0);
      opacity: 0;
    }
    .card-front {
      opacity: 0;
    }
  }
}

.card-partner {
  .card-inner {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      background: rgba($color: $textColor_4, $alpha: 0.2);
      transition: all 0.3s ease-in-out;
    }
    border-radius: 2px;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px;
  }
  .img-content {
    width: 100%;
    height: 100%;
    @include displayFlex(flex, center, center);
    img {
      width: 80%;
    }
  }
  &:hover {
    .card-inner {
      &::before {
        background: rgba($color: $textColor_4, $alpha: 0.4);
      }
    }
  }
}

.card-wallet-info {
  .card-inner {
    background: linear-gradient(90deg, $primary, $primary_2);
    border-radius: 15px;
    padding: 20px;
  }
  .c-title {
    font-size: 18px;
    color: white;
    margin-bottom: 2rem;
  }
  .coin {
    @include displayFlex(flex, flex-end, space-between);
  }
  .price {
    h3 {
      font-size: 24px;
      color: white;
      margin-bottom: 10px;
    }
    h6 {
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
    span {
      text-transform: uppercase;
      margin-left: 3px;
    }
  }
  .icon-arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    @include displayFlex(flex, center, center);
    color: white;
  }
}

.card-userboard {
  margin: 8px 0;
  .inner {
    display: grid;
    grid-template-columns: 40px 70px 1fr 1fr;
    align-items: center;
    background-color: $background_dark2;
    border-radius: 10px;
    padding: 10px 20px 10px 15px;
  }
  .num {
    background-color: $primary;
    border-radius: 50%;
    @include displayFlex(flex, center, center);
    color: white;
    width: 35px;
    height: 35px;
    font-weight: 600;
  }
  .medal {
    padding-left: 15px;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid white;
      padding: 4px;
    }
  }
  .user-name {
    padding-left: 20px;
    h6 {
      font-size: 15px;
      font-weight: 400;
      color: $textColor_1;
    }
  }
  .point {
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    color: $secondary_2;
  }
}

.card-invite {
  margin: 0.8rem 0;
  .card-inner {
    background-color: rgba($color: #fff, $alpha: 0.05);
    border: 1px solid rgba($color: white, $alpha: 0.1);
    border-radius: 15px;
    padding: 40px 30px 30px 30px;
    position: relative;
    overflow: hidden;
    min-height: 305px;
    backdrop-filter: blur(10px);
  }
  .thumb {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .info {
    text-align: center;
    h6 {
      color: white;
      margin-top: 2rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      color: rgba($color: white, $alpha: 0.8);
      margin-bottom: 0;
    }
  }
  .number {
    position: absolute;
    left: -10px;
    top: -70px;
    font-size: 200px;
    font-family: $titleFont;
    line-height: normal;
    color: rgba($color: white, $alpha: 0.1);
    z-index: -1;
    user-select: none;
  }
}

.card-reward {
  margin: 0 0 1rem 0;
  .card-inner {
    background-color: rgba($color: black, $alpha: 0.2);
    border: 1px solid rgba($color: white, $alpha: 0.2);
    padding: 30px 30px 15px 30px;
    border-radius: 15px;
    min-height: 280px;
    backdrop-filter: blur(10px);
  }
  .info {
    h6 {
      font-size: 20px;
      color: white;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      color: rgba($color: white, $alpha: 0.8);
    }
  }
  .btn-link {
    padding: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: $primary;
    .fi {
      margin-left: 10px;
    }
  }
}

.card-game {
  padding: 0 10px;
  margin-bottom: 30px;
  .card-inner {
    padding: 15px 15px 0 15px;
    background-color: rgba($color: white, $alpha: 0.1);
    border-radius: 10px;
    position: relative;
    display: block;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      transition: all 0.2s ease-in-out;
      background: linear-gradient(
        54deg,
        #d9b01f 0%,
        #1e1c20 20%,
        #1e1c20 75%,
        #fff 100%
      );
    }
  }
  .thumb {
    position: relative;
    top: -35px;
    z-index: 20;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  .info {
    position: relative;
    top: -15px;
    z-index: 20;
    .name {
      font-size: 18px;
      color: white;
      margin-bottom: 5px;
      text-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.3);
      @include textTruncate(1);
    }
    .genre {
      font-size: 14px;
      font-weight: 600;
      color: white;
      text-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.3);
      @include textTruncate(1);
      span {
        font-weight: 500;
        color: rgba($color: white, $alpha: 0.8);
      }
    }
  }
  .backdrop-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    .img-inner {
      width: 100%;
      height: 100%;
      filter: blur(20px);
      transform: scale(1.2);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 0.4s ease-in-out;
    }
  }
  &:hover {
    .card-inner {
      &::before {
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
      }
    }
    .backdrop-image {
      .img-inner {
        transform: scale(1.4);
      }
    }
  }
}

.card-team {
  margin-top: 20px;
  .card-inner {
    position: relative;
    display: block;
  }
  .thumb {
    background-color: $card_background_1;
    border-radius: 10px 10px 0 0;
    .img-inner {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
      position: relative;
      box-shadow: 0 10px 35px -1px rgba($color: white, $alpha: 0.05);
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 0% 100%);
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -60px;
        width: 120%;
        height: 10px;
        background: rgba(255, 136, 98, 0.6);
        transform: rotate(-5deg);
        z-index: 2;
        transition: all 0.2s ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -60px;
        width: 120%;
        height: 10px;
        background: rgba(255, 136, 98, 0.5);
        transform: rotate(-5deg);
        z-index: 2;
        transition: all 0.3s ease-in-out;
      }
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .info {
    text-align: center;
    padding: 15px 10px 10px 10px;
    background-color: $card_background_1;
    border-radius: 0 0 10px 10px;
    .name {
      font-size: 16px;
      font-weight: 600;
      color: white;
      margin-bottom: 2px;
      transition: all 0.3s ease-in-out;
      @include textTruncate(1);
    }
    p {
      font-size: 14px;
      font-weight: 300;
      color: rgba($color: white, $alpha: 0.8);
      transition: all 0.3s ease-in-out;
      margin-bottom: 10px;
      @include textTruncate(1);
    }
  }
  .c-social-icons {
    transition: all 0.4s ease-in-out;
    ul {
      @include displayFlex(flex, center, center);
      li {
        margin: 2px;
        a {
          @include displayFlex(flex, center, center);
          width: 34px;
          height: 34px;
          color: white;
          font-size: 18px;
          border-radius: 50%;
          background-color: rgba($color: white, $alpha: 0.2);
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: $secondary_2;
          }
        }
      }
    }
  }
  &:hover {
    .thumb {
      .img-inner {
        &::before {
          bottom: -20px;
          height: 55px;
        }
        &::after {
          bottom: -20px;
          height: 45px;
        }
      }
    }
    .info {
      .name {
        color: $secondary_2;
      }
      p {
        color: white;
      }
    }
  }
}

.card-char-details {
  padding: 10px;
  .thumb {
    position: relative;
    .img-inner {
      position: relative;
      overflow: hidden;
      z-index: 10;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80%;
        transition: all 0.25s ease-in-out;
        background-color: $card_background_1;
        z-index: -1;
        border-radius: 10px;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 44px;
        width: 50%;
        height: 35px;
        background-image: url(../assets/images/game-covers/card-dots.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: left center;
        z-index: -1;
        opacity: 0.2;
      }
      img {
        width: 100%;
        transition: all 0.25s ease-in-out;
      }
    }
    .char-property {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: rgba($color: $color_card_common, $alpha: 0.4);
      padding: 4px 5px;
      z-index: 11;
      ul {
        @include displayFlex(flex, center, flex-start);
        flex-wrap: wrap;
        li {
          width: 30px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .info {
    background-color: $card_background_2;
    padding: 15px 15px 0 15px;
    border-radius: 0 0 0 10px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 22px 22px;
      border-color: transparent transparent $color_card_common transparent;
    }
  }
  .category {
    position: relative;
    border-left: 3px solid $color_card_common;
    margin-bottom: 12px;
    h6 {
      padding-left: 10px;
      color: $color_card_common;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .name {
    font-size: 17px;
    color: white;
    margin-bottom: 15px;
  }
  .price-content {
    padding-bottom: 15px;
    @include displayFlex(flex, center, space-between);
    .battle {
      h6 {
        font-size: 14px;
        font-weight: 500;
        color: white;
        margin-bottom: 5px;
      }
      h5 {
        margin-bottom: 0;
        font-size: 16px;
        color: $color_card_common;
      }
    }
    .price {
      background-color: rgba($color: #000000, $alpha: 0.15);
      padding: 5px;
      border-radius: 5px;
      text-align: right;
      .coin {
        @include displayFlex(flex, center, flex-end);
        img {
          width: 24px;
        }
        h4 {
          font-size: 22px;
          font-weight: 700;
          color: #fe944b;
          margin-bottom: 0;
          margin-left: 5px;
          span {
            margin-left: 2px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .usd {
        margin-top: 3px;
        h6 {
          font-size: 16px;
          color: white;
          span {
            font-size: 14px;
            margin-left: 3px;
            font-weight: 400;
          }
        }
      }
    }
  }
  &.color-epic {
    .thumb {
      .char-property {
        background-color: rgba($color: $color_card_epic, $alpha: 0.5);
      }
    }
    .info {
      &::before {
        border-color: transparent transparent $color_card_epic transparent;
      }
    }
    .category {
      border-color: $color_card_epic;
      h6 {
        color: $color_card_epic;
      }
    }
    .price-content {
      .battle {
        h5 {
          color: $color_card_epic;
        }
      }
    }
  }
  &.color-legendary {
    .thumb {
      .char-property {
        background-color: rgba($color: $color_card_legendary, $alpha: 0.6);
      }
    }
    .info {
      &::before {
        border-color: transparent transparent $color_card_legendary transparent;
      }
    }
    .category {
      border-color: $color_card_legendary;
      h6 {
        color: $color_card_legendary;
      }
    }
    .price-content {
      .battle {
        h5 {
          color: $color_card_legendary;
        }
      }
    }
  }
  &.color-common {
    .thumb {
      .char-property {
        background-color: rgba($color: $color_card_common, $alpha: 0.6);
      }
    }
    .info {
      &::before {
        border-color: transparent transparent $color_card_common transparent;
      }
    }
    .category {
      border-color: $color_card_common;
      h6 {
        color: $color_card_common;
      }
    }
    .price-content {
      .battle {
        h5 {
          color: $color_card_common;
        }
      }
    }
  }
  &:hover {
    .thumb {
      .img-inner {
        &::before {
          height: 100%;
        }
        img {
          transform: scale(1.03);
        }
      }
    }
  }
}

.card-buycoin {
  margin: 1rem 0;
  .card-inner {
    background-color: rgba($color: white, $alpha: 0.06);
    border: 1px solid rgba($color: white, $alpha: 0.2);
    border-radius: 25px;
    padding: 20px 30px;
  }
  .thumb {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .info {
    margin-top: 1rem;
    text-align: center;
    min-height: 300px;
    h5 {
      color: white;
      margin-bottom: 1.5rem;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      color: rgba($color: white, $alpha: 0.8);
    }
  }
}

@media (max-width: 1400px) {
  .card-reward {
    .card-inner {
      min-height: 300px;
    }
  }
  .card-buycoin {
    .info {
      min-height: 305px;
    }
  }
}

@media (max-width: 1199px) {
  .card-reward {
    .card-inner {
      min-height: 350px;
    }
  }
  .card-ecosystem {
    .thumb {
      width: 155px;
      height: 155px;
    }
  }
  .card-buycoin {
    .info {
      min-height: 360px;
    }
  }
}
@media (max-width: 992px) {
  .card-ecosystem {
    .thumb {
      width: 170px;
      height: 170px;
    }
  }
  .card-buycoin {
    .info {
      min-height: 310px;
    }
  }
  .card-invite {
    .card-inner {
      min-height: 285px;
    }
  }
  .card-reward {
    .card-inner {
      min-height: unset;
    }
  }
}

@media (max-width: 768px) {
  .card-ecosystem {
    margin: 10px 0 20px 0;
    .thumb {
      width: 50%;
      height: 160px;
    }
  }
  .card-partner {
    .card-inner {
      padding: 10px;
    }
    .img-content {
      img {
        width: 90%;
      }
    }
  }
  .card-wallet-info {
    margin-bottom: 15px;
    .c-title {
      font-size: 16px;
    }
    .price {
      h3 {
        font-size: 20px;
      }
      h6 {
        font-size: 13px;
      }
    }
  }
  .card-userboard {
    .inner {
      grid-template-columns: 30px 50px 1fr 1fr;
    }
    .num {
      font-size: 12px;
    }
    .user-name h6 {
      font-size: 14px;
    }
    .point {
      font-size: 14px;
    }
  }
  .card-buycoin {
    .info {
      min-height: auto;
    }
  }
  .card-invite {
    .card-inner {
      min-height: unset;
      height: auto;
      padding: 30px 20px 20px 20px;
    }
  }
  .card-game {
    .info {
      .name {
        font-size: 16px;
      }
      .genre {
        font-size: 13px;
      }
    }
  }
  .card-char-details {
    padding: 0 5px;
    .thumb .char-property ul li {
      width: 24px;
    }
    .info {
      padding: 10px 10px 0 10px;
      &::before {
        border-width: 0 0 18px 18px;
      }
    }
    .category {
      margin-bottom: 8px;
      h6 {
        font-size: 12px;
        padding-left: 6px;
      }
    }
    .name {
      font-size: 15px;
    }
    .price-content {
      .battle {
        h6 {
          font-size: 13px;
          margin-bottom: 3px;
        }
        h5 {
          font-size: 14px;
        }
      }
      .price {
        .coin {
          img {
            width: 20px;
          }
          h4 {
            font-size: 18px;
            span {
              font-size: 12px;
            }
          }
        }
        .usd {
          h6 {
            font-size: 14px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .card-team {
    .info {
      .name {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 575px) {
  .card-ecosystem {
    .thumb {
      width: 130px;
      height: 160px;
    }
    .card-front {
      padding-bottom: 0;
    }
    .card-back {
      position: unset;
      transform: unset;
      padding-top: 0;
      .info {
        .name {
          display: none;
        }
      }
    }
    .arrow-back {
      display: none;
    }
    &:hover {
      .card-inner {
        transform: unset;
      }
      .thumb {
        transform: unset;
        opacity: unset;
      }
      .card-front {
        opacity: unset;
      }
    }
  }
  .card-team {
    .info {
      .name {
        font-size: 12px;
      }
      p {
        font-size: 10px;
      }
    }
  }
}
