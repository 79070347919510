.coin-inputgroup {
  position: relative;
  .custom-select-container {
    position: absolute;
    right: 0;
    top: 26px;
    .select-header {
      color: rgba($color: #000000, $alpha: 0.8);
    }
    .select-list {
      background-color: white;
      box-shadow: $boxshadow;
      .item {
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
        .title {
          color: rgba($color: #000000, $alpha: 0.8);
        }
      }
    }
  }
  .input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 60px;
    padding: 5px 90px 5px 15px;
  }
  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: rgba($color: #000000, $alpha: 0.7);
    margin-bottom: 5px;
  }
  .message-status {
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    &.error {
      color: $error;
    }
    &.success {
      color: $success;
    }
    &.warning {
      color: $secondary_2;
    }
  }
  .coin-icon {
    position: absolute;
    top: 35px;
    right: 15px;
    @include displayFlex(flex, center, flex-end);
    .thumb {
      width: 30px;
      img {
        width: 100%;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
}

.input-referralCode {
  position: relative;
  .input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 60px;
    padding: 5px 115px 5px 15px;
  }
  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: rgba($color: #000000, $alpha: 0.7);
    margin-bottom: 5px;
  }
  .message-status {
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    &.error {
      color: $error;
    }
    &.success {
      color: $success;
    }
    &.warning {
      color: $secondary_2;
    }
  }
  .btn-apply {
    position: absolute;
    right: 5px;
    top: 30px;
    background-color: $primary;
    color: white;
    display: inline-block;
    padding: 10px 30px;
    font-size: 13px;
    border-radius: 60px;
    border: none;
    text-transform: capitalize;
    &:hover,
    &:focus {
      background-color: $primary_2;
    }
  }
}

.inputgroup {
  margin-bottom: 15px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-bottom: 5px;
  }
  .input {
    width: 100%;
    border-radius: 60px;
    height: 50px;
    padding: 5px 15px;
    border: none;
  }
}

.select-group {
  margin-bottom: 15px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-bottom: 5px;
  }
  .form-select {
    width: 100%;
    width: 100%;
    border-radius: 60px;
    height: 50px;
    padding: 5px 15px;
    border: none;
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.textarea-group {
  margin-bottom: 15px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-bottom: 5px;
  }
  .textarea {
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    border: none;
    // background-color: rgba($color: white, $alpha: 0.3);
  }
}

.progress-group {
  margin-top: 3rem;
  .countdown {
    font-size: 30px !important;
    color: #fff !important;
  }
  label {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
  }
  .progress {
    border-radius: 30px;
    background-color: rgba($color: white, $alpha: 0.2);
  }
  .progress-bar {
    border-radius: 30px;
    background-color: $primary;
  }
}

.form-check{
  margin: 8px 0;
  .form-check-input{
    background-color: transparent;
    border-width: 2px;
    border-color: rgba($color: white, $alpha: 0.4);
    &:checked{
      background-color: $secondary_2;
      border-color: $secondary_2;
    }
    &:hover,&:focus{
      box-shadow: none;
    }
  }
  .form-check-label{
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
}