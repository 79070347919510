.swiper-gameHeader {
  .swiper-slide {
    .img-inner {
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
      picture {
        width: 100%;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-family: $iconFont_Regular;
      font-size: 24px;
      color: white;
    }
    width: 34px;
    height: 34px;
    border-radius: 5px;
    background-color: rgba($color: white, $alpha: 0.4);
    &:hover {
      background-color: white;
      &::after {
        color: black;
      }
    }
  }
  .swiper-button-next {
    width: 40px;
    height: 40px;
    &::after {
      content: "\f11f";
    }
    top: unset;
    bottom: 17px;
    left: 58px;
    right: unset;
  }
  .swiper-button-prev {
    &::after {
      content: "\f11e";
    }
    top: unset;
    bottom: 20px;
    left: 20px;
  }
  .swiper-pagination-bullets {
    display: inline-block !important;
    width: auto !important;
    left: unset;
    right: 20px;
    bottom: 20px;
  }
  .swiper-pagination-bullet {
    background-color: white;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    &.swiper-pagination-bullet-active {
      width: 25px;
      border-radius: 1px;
    }
  }
}

.swiper-gameHeader {
  .swiper-slide {
    .img-inner {
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }
}

.card-gameslist-section {
  margin-bottom: 100px;
  .grid-layout {
    // display: grid;
    // grid-template-columns: 320px auto;
  }
  .mini-titles {
    @include displayFlex(none, center, space-between);
    margin-bottom: 3rem;
    .m-title {
      font-size: 18px;
      color: white;
      margin-bottom: 0;
    }
    .btn-show-filter {
      background-color: transparent;
      border: 2px solid white;
      border-radius: 8px;
      color: white;
      padding: 8px 15px;
      .fi {
        margin-right: 10px;
        position: relative;
        top: 3px;
      }
    }
  }
  .cards-list {
    // padding-left: 30px;
    .inner {
      display: grid;
      // grid-template-columns: 33.33% 33.33% 33.33%;
      grid-template-columns: 25% 25% 25% 25%;
    }
  }
  .filters {
    .inner {
      background-color: #262527;
      border-radius: 0 0 15px 15px;
    }
    .filter-title {
      @include displayFlex(flex, center, space-between);
      padding: 20px;
      background-color: #262527;
      border-radius: 15px 15px 0 0;
      h2 {
        margin-bottom: 0;
        font-size: 20px;
        color: white;
      }
      .btns-group {
        display: flex;
        align-items: center;
      }
      .btn-clear-filter {
        padding: 0;
        background-color: transparent;
        color: $secondary_2;
        border: none;
        font-size: 14px;
        font-weight: 500;
      }
      .btn-close-filter {
        padding: 0;
        width: 38px;
        height: 38px;
        border: 1px solid rgba($color: white, $alpha: 0.4);
        background-color: transparent;
        color: white;
        display: inline-block;
        border-radius: 50%;
        margin-left: 20px;
        display: none;
        .fi {
          position: relative;
          top: 2px;
        }
      }
    }
    .box {
      border-top: 1px solid rgba($color: white, $alpha: 0.2);
    }
    .Collapsible {
      padding: 20px;
      .Collapsible__trigger {
        display: block;
        color: white;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        &::before {
          content: "\f11d";
          font-family: $iconFont_Regular;
          position: absolute;
          right: 0;
          top: 0;
          color: white;
          font-size: 16px;
          transition: all 0.2s ease-in-out;
        }
        &.is-closed {
          &::before {
            transform: rotate(-90deg);
          }
        }
        &.is-open {
          &::before {
            transform: rotate(0);
          }
        }
      }
      .Collapsible__contentInner {
        padding-top: 20px;
      }
    }
  }
}

.filter-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  display: none;
  z-index: 1500;
  &.active {
    display: block;
  }
}

.gameHederinfo-section {
  position: relative;
  top: -100px;
  .grid-layout {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-areas: "gameGallery gameInfo" "gameDescription gameInfo";
  }

  .game-gallery {
    grid-area: gameGallery;
    padding-right: 30px;
  }
  .game-description {
    grid-area: gameDescription;
    padding-right: 30px;
    .game-actions {
      text-align: right;
      margin: 2rem 0;
      @include displayFlex(flex, center, flex-end);
      flex-wrap: wrap;
      ul {
        margin: 10px 20px 10px 0;
        li {
          font-size: 13px;
          color: white;
          .fi {
            margin-right: 5px;
          }
        }
      }
    }
    h3 {
      color: white;
      margin-bottom: 1rem;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      color: rgba($color: white, $alpha: 0.8);
    }
  }
  .game-information {
    grid-area: gameInfo;
    .info-inner {
      position: sticky;
      top: 50px;
      .cover {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
    .game-name {
      h2 {
        font-size: 18px;
        color: white;
        margin-top: 20px;
        line-height: 28px;
      }
    }
    .info-list {
      .list-inner {
        margin-top: 25px;
        border: 1px solid rgba($color: white, $alpha: 0.2);
        border-radius: 10px;
        padding: 15px;
      }
      ul {
        li {
          @include displayFlex(flex, center, space-between);
          font-size: 14px;
          margin: 10px 0;
          .name {
            font-weight: 600;
            color: white;
          }
          .desc {
            font-weight: 400;
            color: white;
            .fi {
              color: goldenrod;
              margin: 0 5px;
              position: relative;
              top: 3px;
            }
            small {
              font-size: 18px;
              background-color: rgba($color: white, $alpha: 0.1);
              margin: 3px;
              width: 28px;
              height: 28px;
              border-radius: 4px;
              display: inline-block;
            }
          }
        }
      }
      .tags {
        margin-top: 20px;
        ul {
          @include displayFlex(flex, center, flex-start);
          flex-wrap: wrap;
          li {
            background-color: rgba($color: white, $alpha: 0.1);
            color: white;
            margin: 5px 8px 5px 0;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .card-gameslist-section {
    .grid-layout {
      grid-template-columns: 290px auto;
    }
  }
}
@media (max-width: 1199px) {
  .card-gameslist-section {
    .cards-list {
      .inner {
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
    }
  }
}
@media (max-width: 992px) {
  .swiper-gameHeader {
    .swiper-button-next,
    .swiper-button-prev {
      width: 35px;
      height: 35px;
    }
    .swiper-button-next {
      bottom: 10px;
      left: 50px;
    }
    .swiper-button-prev {
      bottom: 10px;
      left: 10px;
    }
    .swiper-pagination-bullets {
      bottom: 10px;
    }
  }
  .card-gameslist-section {
    .grid-layout {
      grid-template-columns: 100%;
    }
    .mini-titles {
      display: flex;
    }
    .cards-list {
      padding-left: 0;
      .inner {
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
    }
    .filters {
      position: fixed;
      left: -300px;
      top: 0;
      z-index: 1501;
      width: 300px;
      height: 100%;
      transition: all 0.3s ease-in-out;
      &.active {
        left: 0;
      }
      .filter-title {
        background-color: $background;
        border-radius: 0 15px 0 0;
        .btn-close-filter {
          display: block;
        }
      }
      .inner {
        height: calc(100% - 78px);
        overflow-y: auto;
        padding-bottom: 50px;
        border-radius: 0;
      }
    }
  }
  .gameHederinfo-section {
    .grid-layout {
      grid-template-columns: 100%;
      grid-template-areas: "gameInfo" "gameGallery" "gameDescription";
    }
    .game-gallery {
      padding-right: 0;
      width: 80%;
      margin: 0 auto;
    }
    .game-information {
      margin-bottom: 30px;
      .info-inner {
        display: grid;
        grid-template-columns: auto 200px;
        grid-template-areas: "info image";
        align-items: flex-end;
      }
      .game-name {
        h2 {
          margin-top: 0;
        }
      }
      .cover {
        grid-area: image;
      }

      .info-list {
        grid-area: info;
        padding-right: 20px;
      }
    }
    .game-description {
      padding-right: 0;
      .game-actions {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .swiper-gameHeader {
    .swiper-button-next,
    .swiper-button-prev {
      width: 24px;
      height: 24px;
      background-color: transparent;
    }
    .swiper-button-next {
      bottom: 5px;
      left: 35px;
    }
    .swiper-button-prev {
      bottom: 5px;
      left: 5px;
    }
    .swiper-pagination-bullets {
      bottom: 5px;
    }
  }
  .card-gameslist-section {
    .cards-list {
      .inner {
        grid-template-columns: 50% 50%;
      }
    }
  }
  .gameHederinfo-section {
    .game-gallery {
      width: 100%;
    }
  }
}
@media (max-width: 575px) {
  .card-gameslist-section .mini-titles {
    .m-title {
      font-size: 16px;
    }
    .btn-show-filter {
      padding: 6px 10px;
      font-size: 13px;
    }
  }
}
@media (max-width: 450px) {
  .card-gameslist-section {
    .cards-list {
      .inner {
        grid-template-columns: 100%;
      }
      .card-game {
        padding: 0;
      }
    }
  }
}
