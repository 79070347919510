.teams-section {
  margin: 1rem 0 0 0;
  padding: 5rem 0 6rem 0;
  // background-color: rgba($color: $background_dark2, $alpha: 0.2);
  .section-inner {
    margin-top: 3rem;
  }
  .tab-content {
    .tab-pane {
      animation: swipeRightsmall 0.3s ease-in-out;
    }
    .tab-inner {
      @include displayFlex(flex, center, center);
      flex-wrap: wrap;
      padding-top: 30px;
      .card-team {
        flex: 0 0 20%;
        width: 20%;
        padding: 10px;
      }
    }
  }
  .nav-pills {
    .nav-link {
      padding: 1rem 1.5rem;
      color: rgba($color: white, $alpha: 0.7);
      border-radius: 0;
      position: relative;
      background-color: transparent;
      &:first-child {
        &::before {
          border-radius: 50px 0 0 50px;
        }
      }
      &:last-child {
        &::before {
          border-radius: 0 50px 50px 0;
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        transition: all 0.3s ease-in-out;
        z-index: 1;
      }
      &.active {
        color: white;
        background-color: rgba($color: #000000, $alpha: 0.3);
        &:first-child {
          border-radius: 50px 0 0 50px;
        }
        &:last-child {
          border-radius: 0 50px 50px 0;
        }
        &::before {
          background-color: $primary;
          border-radius: 50px;
        }
      }
      span {
        position: relative;
        z-index: 5;
      }
    }
  }
}

@media (max-width: 1199px) {
  .teams-section {
    .tab-content {
      .tab-inner {
        .card-team {
          flex: 0 0 25%;
          width: 25%;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .teams-section {
    .tab-content {
      .tab-inner {
        .card-team {
          flex: 0 0 33.33%;
          width: 33.33%;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .teams-section {
    .nav-pills {
      .nav-link {
        padding: 0.8rem;
        font-size: 14px;
        flex-grow: 1;
      }
    }
    .tab-content {
      .tab-inner {
        .card-team {
          flex: 0 0 50%;
          width: 50%;
          padding: 5px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .teams-section {
    .nav-pills {
      .nav-link {
        width: 100%;
        margin: 5px 0;
        &::before {
          border-radius: 50px !important;
        }
      }
    }
  }
}
